
import Dashboard from "../Layout/Dashboard";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useEffect, useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { USER_ROLES_DROPDOWN } from "../constants";
import { useUsersList, useClients, useUsersClientList } from "../hooks/queries";
import { MultiSelect } from "primereact/multiselect";
import { saveUserClient } from "../services/users";
import { useMutation } from "@tanstack/react-query";
import { Toast } from "primereact/toast";

/**
 * User table and management page
 */
export default function UsersClient() {
  // Dialog visibility
  const [showDialog, setShowDialog] = useState(false);
  // State for storing users
  const [user, setUser] = useState(null);
  const useUserListQuery = useUsersList();
  const useUserClientListQuery = useUsersClientList(user);
  const clientsQuery = useClients(false);

  const userClients = useUserClientListQuery.data?.results?.map((client) => {
    return client.company_client;
  });
  const [clientsSelect, setClientsSelect] = useState([]);

  const toast = useRef(null);

  const userClient = useMutation({
    mutationFn: () => { return saveUserClient({ userID: user, clientsID: clientsSelect })},
    onSuccess: ({ type, message }) => {
      if (type === "error") {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: message,
        });
      } else {
        toast.current.show({
          severity: "success",
          summary: "Éxito",
          detail: message,
        });
      }
    },
  });

  useEffect(() => {
    if (useUserClientListQuery.data?.results) {
      setClientsSelect(userClients);
    }
    // eslint-disable-next-line
}, [useUserClientListQuery.isSuccess]);

  return (
    <Dashboard headerTitle="Gestionar usuarios">
      <Toast ref={toast} position="top-center" />
      <div className="p-fluid">
        <div className="p-field">
          <label htmlFor="assignClients">Asignar clientes a usuario</label>
        </div>
        <div className="p-field">
          <label htmlFor="users">Usuario</label>
          <Dropdown
            inputId="users"
            name="users"
            optionLabel="username"
            optionValue="id"
            value={user}
            filter
            placeholder="Seleccione un usuario"
            options={useUserListQuery?.data} // Replace with the appropriate options for users
            onChange={(e) => setUser(e.value)} // Replace with your handler
          />
        </div>
        <div className="p-field">
          <label htmlFor="clients">Clientes</label>
          <MultiSelect
            value={clientsSelect}
            inputId="clients"
            name="clients"
            optionLabel="name"
            optionValue="id"
            placeholder="Seleccione uno o más clientes"
            loading={clientsQuery?.isLoading}
            options={clientsQuery?.data} // Replace with the appropriate options for clients
            onChange={(e) => setClientsSelect(e.value)} // Replace with your handler
          />
        </div>
        <Button
          icon="pi pi-save"
          label="Guardar"
          className="p-button-success"
          type="button"
          style={{ maxWidth: "fit-content" }}
          onClick={() => {
            userClient.mutate();
          }}
        />
        {/* <DataTable emptyMessage="Sin usuarios registrados.">
          <Column field="email" header="Correo electrónico" />
          <Column field="role" header="Rol" />
        </DataTable> */}
      </div>
      <NewUserDialog
        show={showDialog}
        onHide={() => {
          if (showDialog) setShowDialog(false);
        }}
      />
    </Dashboard>
  );
}

function NewUserDialog({ show, onHide }) {
  // Form state
  const [email, setEmail] = useState("");
  const [role, setRole] = useState(null);

  const canSubmit = email.length > 0 && role !== null;

  return (
    <Dialog
      header="Nuevo usuario"
      visible={show}
      onHide={onHide}
      draggable={false}
    >
      <form className="p-fluid">
        <div className="p-field">
          <label htmlFor="email">Correo electrónico</label>
          <InputText
            id="email"
            name="email"
            required
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="p-field">
          <label htmlFor="role">Rol</label>
          <Dropdown
            inputId="role"
            name="role"
            required
            placeholder="Seleccione un rol"
            value={role}
            onChange={(e) => setRole(e.value)}
            options={USER_ROLES_DROPDOWN}
          />
        </div>

        <Button label="Crear" disabled={!canSubmit} />
      </form>
    </Dialog>
  );
}
