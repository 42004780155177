import React, { useRef, useState } from "react";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./MedicalCasesListing.css"
import { Dropdown } from "primereact/dropdown";
import {
  currencyFormat,
  formatCi,
  formatDateString,
  formatFullNumber,
  // getStatusChangeOptionsForRole,
  readableSex,
} from "../utils";
import { Link } from "react-router-dom";
import StatusTag from "../components/StatusTag";
// import { Button } from "primereact/button";

import { CaseStatus, GENDERS } from "../constants";
import { Paginator } from "primereact/paginator";
import Dashboard from "../Layout/Dashboard";
import { useMedicalCases, useRequirementTypes, useMedicalCasesCount } from "../hooks/queries";
import {
  ChangeStatusMultipleModal,
  ChangeStatusSingleModal,
} from "../components/StatusChangeModal";
import { usePatchMedicalCase } from "../hooks/mutations";
import { Toast } from "primereact/toast";
// import { StatusSelector } from "../components/StatusChangeSelector";
import { useMutation } from "@tanstack/react-query";
import { updateMultipleCasesStatus } from "../services/medicalCases";
import { Calendar } from "primereact/calendar";
import { FloatLabel } from "primereact/floatlabel";
import { PaginatorTemplate } from "../Layout/Paginator/PaginatorTemplate";
import {Card} from "primereact/card";


/**
 * Dropdown to filter case patient sex
 */
const sexFilterTemplate = (options) => (
  <Dropdown
    value={options.value}
    options={GENDERS}
    onChange={(e) => options.filterApplyCallback(e.value)}
    placeholder="Seleccionar sexo"
    className="p-column-filter min-w-min"
    showClear
    style={{ maxWidth: "10rem" }}
  />
);

/**
 * Template to show the amount in currency format
 */
const amountBodyTemplate = (rowData) => {
  return currencyFormat(rowData.amount ?? 0);
};

/**
 * Status tag for filter selector
 * @param {string} option
 */
const statusItemTemplate = (option) => {
  return <StatusTag value={option?.value} />;
};

const caseStatusArray = Object.entries(CaseStatus.labels).map(([key, item]) => ({
  label: item,
  value: key,
})) 

/**
 * Status filter dropdown
 * @param {string[]} options
 */
const statusRowFilterTemplate = (options) => {
  return (
    <Dropdown
      value={options.value}
      options={caseStatusArray}
      onChange={(e) => {
        options.filterApplyCallback(e.value);
      }}
      itemTemplate={statusItemTemplate}
      placeholder="Seleccionar status"
      className="p-column-filter min-w-min"
      showClear
    />
  );
};

/**
 * Medical cases table
 */
export default function MedicalCasesListing() {
  // const [pageSize, setPageSize] = useState(50);
  const [pageSize, setPageSize] = useState(20);
  const [lazyFilters, setLazyFilters] = useState({
    // global: { value: "", matchMode: FilterMatchMode.CONTAINS },
    // created: { value: "", matchMode: FilterMatchMode.CONTAINS },
    // id: { value: "", matchMode: FilterMatchMode.CONTAINS },
    status: null,
  });
  const [lazyState, setLazyState] = useState({
    first: 1,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      // name: { value: '', matchMode: 'contains' },
      // 'country.name': { value: '', matchMode: 'contains' },
      // company: { value: '', matchMode: 'contains' },
      // 'representative.name': { value: '', matchMode: 'contains' }
    },
  });

  // Get medical cases
  const casesQuery = useMedicalCases({
    page: lazyState.page,
    pageSize,
    filters: lazyFilters,
  });

  const casesCountQuery = useMedicalCasesCount();

  // Case detail link
  const ViewDetailBodyTemplate = (row) => (
    <Link to={String(row.id)} className="p-button  text-sm p-2">
      <span className="p-button-icon p-button-icon-left pi pi-eye"></span>
      <span className="p-button-label">Ver</span>
    </Link>
  );

  // Status change
  const [rowChanging, setRowChanging] = useState(null);
  const [newStatus, setNewStatus] = useState(null);
  const [newStatusMultiple, setNewStatusMultiple] = useState(null);
  const [showMultipleModal, setShowMultipleModal] = useState(false);
  const [dateFilter, setDateFilter] = useState(null);

  const toastRef = useRef(null);

  // Table multiple selection
  const [selectedRows, setSelectedRows] = useState(null);

  // Get requirement types
  const requirementTypesQuery = useRequirementTypes();

  // Update medical case from listing
  const caseMutation = usePatchMedicalCase({
    onSuccess: ({ type, message }) => {
      if (type === "success") {
        toastRef.current.show({
          severity: "success",
          summary: "Éxito",
          detail: message,
        });
        casesQuery.refetch();
        setRowChanging(null);
      } else {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: message,
        });
        setRowChanging(null);
      }
    },
  });

  // Update multiple cases status
  const caseMultipleMutation = useMutation({
    mutationFn: updateMultipleCasesStatus,
    onSuccess: ({ type, message }) => {
      if (type === "success") {
        toastRef.current.show({
          severity: "success",
          summary: "Éxito",
          detail: message,
        });
        casesQuery.refetch();
        setShowMultipleModal(false);
        setSelectedRows([]);
        setNewStatusMultiple(null);
      } else {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: message,
        });
        setShowMultipleModal(false);
      }
    },
  });

  // Global filters
  const [filters] = useState({
    // global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    created: { value: null, matchMode: FilterMatchMode.CONTAINS },
    id: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "patient_.sex": { value: null, matchMode: FilterMatchMode.EQUALS },
    requirement_type_names: { value: null, matchMode: FilterMatchMode.EQUALS },
    "holder_.ci": { value: null, matchMode: FilterMatchMode.CONTAINS },
    "patient_.ci": { value: null, matchMode: FilterMatchMode.CONTAINS },
    "patient_.name": { value: null, matchMode: FilterMatchMode.CONTAINS },
    requirement_names: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "patient_.birthdate": { value: null, matchMode: FilterMatchMode.CONTAINS },
    diagnosis_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    medical_center_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    subsidiary_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "patient_.phone": { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  // const [globalFilterValue, setGlobalFilterValue] = useState("");

  // const onGlobalFilterChange = (e) => {
  //   const value = e.target.value;
  //   let _filters = { ...filters };

  //   _filters["global"].value = value;

  //   setFilters(_filters);
  //   setGlobalFilterValue(value);
  // };

  /**
   * Status tag for table body
   */
  const statusBodyTemplate = (rowData) => (
    <StatusTag value={rowData.status} />
    // <StatusSelector
    //   currentStatus={rowData.status}
    //   selectedStatus={rowData.status}
    //   onChange={(e) => {
    //     setNewStatus(e.value);
    //     setRowChanging(rowData);
    //   }}
    // />
  );

  /**
   * Dropdown to filter case requirement type
   */
  const requirementTypeFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      loading={requirementTypesQuery.isLoading}
      disabled={requirementTypesQuery.isLoading}
      options={requirementTypesQuery.data ?? []}
      optionValue="id"
      optionLabel="name"
      onChange={(e) => {
        console.log(e);
        return options.filterApplyCallback(e.value);
      }}
      placeholder="Seleccionar tipo"
      className="p-column-filter min-w-min"
      showClear
      filter
      style={{ maxWidth: "10rem" }}
    />
  );

  /**
   * Page event
   *
   */
  const onPageChange = (e) => {
    console.log(e);
    setPageSize(e.rows);
    setLazyState({ ...lazyState, first: e.first, page: e.page + 1 });
  };

  const onFilter = (event) => {
    event["first"] = 0;
    setLazyState(event);
    // const dateFormat = event.filters.created.value.toISOString().split('T')[0];
    // console.log("dateformat", dateFormat)
    setLazyFilters({
      ...lazyFilters,
      status: event.filters.status.value,
      id__contains: event.filters.id.value,
      holder__ci__icontains: event.filters["holder_.ci"].value,
      patient__ci__icontains: event.filters["patient_.ci"].value,
      patient_name__icontains: event.filters["patient_.name"].value,
      medical_center__name__icontains: event.filters.medical_center_name.value,
      requirements__requirement__type_requirement:
        event.filters.requirement_type_names.value,
    });
  };

  /**
   * Cases table header
   */
  const TableHeader = ({ newStatus, changeNewStatus, onStatusChangeClick }) => {
    return (
      <div className="full-w-header">
        <div className="mt-3 mb-2">
          <FloatLabel>
            <Calendar
              locale="es"
              inputId="created_date"
              value={dateFilter}
              onChange={(e) => {
                // options.filterCallback(e.value, options.index)
                // console.log("Value",e.value)
                console.log(e.originalEvent.type);
                if (
                  e.originalEvent.type === "click" ||
                  e.originalEvent.type === "keypress"
                ) {
                  console.log(e);
                  setDateFilter(e.value);

                  const dateFormat = e.value?.toISOString().split("T")[0];
                  console.log(dateFormat);
                  setLazyFilters({
                    ...lazyFilters,
                    created_at__date: dateFormat,
                  });
                }
                if (e.value === dateFilter) return;

                if (!e.value) {
                  setDateFilter(null);
                }
              }}
              dateFormat="mm/dd/yy"
              placeholder="dia/mes/año"
              mask="99/99/9999"
              showButtonBar
            />
            <label htmlFor="created_date">Buscar por fecha</label>
          </FloatLabel>
        </div>
        {/* <div>
          <Dropdown
            placeholder="Cambiar status en lote"
            options={getStatusChangeOptionsForRole()}
            itemTemplate={statusItemTemplate}
            valueTemplate={
              newStatus !== null && (
                <span>
                  Cambiar a: <StatusTag value={newStatus} />
                </span>
              )
            }
            value={newStatus}
            onChange={(e) => changeNewStatus(e.value)}
          />
          <Button
            label="Aplicar"
            className="ml-3"
            onClick={onStatusChangeClick}
          />
        </div> */}
        {/* <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Buscar palabra clave"
          />
        </IconField> */}
      </div>
    );
  };

  return (
    <Dashboard headerTitle="Casos">
      <div className="card">
        <Toast ref={toastRef} position="top-center" />
        <div className="flex flex-wrap gap-3 mb-2">
          <Card title="" className="w-3 mt-2 mb-2 card-count md:w-3  lg:w-2">
            {casesQuery.data?.count && (
              <div className="text-xl text-center">
                <p className="m-0">
                  <strong>
                    {casesCountQuery.data?.pending_count}
                    </strong> 
                  </p>
                <StatusTag value={CaseStatus.PENDIENTE} />  
              </div>
            )}
          </Card>
          <Card title="" className="w-3 mt-2 mb-2 card-count md:w-3 lg:w-2">
            {casesQuery.data?.count && (
              <div className="text-xl text-center">
                <p className="m-0">
                  <strong>
                      {casesCountQuery.data?.scheduled_count}
                    </strong> 
                  </p>
                <StatusTag value={CaseStatus.RESCHEDULED} />  
              </div>
            )}
          </Card>
        </div>
        <DataTable
          // paginator
          value={casesQuery.data?.results ?? []}
          // rows={50}
          dataKey="id"
          size={"small"}
          filters={filters}
          // onPage={onPage}
          // first={lazyState.first}
          loading={casesQuery.isLoading}
          // globalFilterFields={[
          //   "name",
          //   "sex",
          //   "requirementType",
          //   "status",
          // ]}
          onFilter={onFilter}
          header={
            <TableHeader
              newStatus={newStatusMultiple}
              changeNewStatus={setNewStatusMultiple}
              onStatusChangeClick={() => setShowMultipleModal(true)}
            />
          }
          emptyMessage="No se hallaron casos."
          selectionMode="checkbox"
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          stripedRows
          scrollable
          removableSort
        >
          <Column selectionMode="multiple" headerStyle={{ width: "3rem" }} />
          <Column
            field="created"
            header="Fecha"
            // filter
            dataType="date"
            filterPlaceholder="Buscar por fecha"
            // showFilterMatchModes={false}
            // showClearButton={false}
            // showApplyButton={false}
            // filterElement={dateFilterTemplate}
          />
          <Column
            field="id"
            header="ID #"
            sortable
            filter
            filterPlaceholder="Buscar por Nro. de Caso"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
          />
          <Column
            field="holder_.ci"
            header="CI Titular"
            filter
            filterPlaceholder="Buscar por Titular"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
            bodyClassName="readable-cell"
            body={(row) => formatCi(row.holder_.ci)}
          />
          <Column
            field="patient_.ci"
            header="CI Paciente"
            filter
            filterPlaceholder="Buscar por CI Paciente"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
            bodyClassName="readable-cell"
            body={(row) => formatCi(row.patient_.ci)}
          />
          <Column
            field="patient_.name"
            header="Nombre Paciente"
            filter
            filterPlaceholder="Buscar por Nombre"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
            bodyClassName="readable-cell"
          />
          <Column
            field="subsidiary_name"
            header="Filial"
            filter
            filterPlaceholder="Buscar por Filial"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
          />
          <Column
            field="patient_.phone"
            header="Teléfono Paciente"
            filter
            filterPlaceholder="Buscar por Teléfono"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
            bodyClassName="readable-cell"
            body={(row) => formatFullNumber(row.patient_.phone)}
          />
          <Column
            field="patient_.birthdate"
            header="Fecha de Nacimiento"
            filter
            filterPlaceholder="Buscar por Fecha"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
            body={(row) => formatDateString(row.patient_.birthdate)}
          />
          <Column
            field="patient_.sex"
            header="Sexo"
            filter
            filterElement={sexFilterTemplate}
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
            body={(row) => readableSex(row.patient_.sex)}
          />
          <Column
            field="requirement_type_names"
            header="Tipo de requerimiento"
            filter
            filterElement={requirementTypeFilterTemplate}
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
          />
          <Column
            field="requirement_names"
            header="Requerimiento"
            filter
            filterPlaceholder="Buscar por Requerimiento"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
            bodyClassName="readable-cell"
          />
          <Column
            field="amount"
            header="Monto (Bs.)"
            filter
            filterPlaceholder="Buscar por Monto"
            showFilterMenu={false}
            body={amountBodyTemplate}
            bodyClassName="readable-cell"
            sortable
          />
          <Column
            field="diagnosis_name"
            header="Diagnóstico"
            filter
            filterPlaceholder="Buscar por Diagnóstico"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
            bodyClassName="readable-cell"
          />
          <Column
            field="medical_center_name"
            header="Proveedor"
            filter
            filterPlaceholder="Buscar por Proveedor"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
            bodyClassName="readable-cell"
          />
          <Column
            field="status"
            header="Status"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
            body={statusBodyTemplate}
            filter
            filterElement={statusRowFilterTemplate}
            frozen
            alignFrozen="right"
          />
          <Column
            header="Acción"
            body={ViewDetailBodyTemplate}
            frozen
            alignFrozen="right"
          />
        </DataTable>
        {/* <Paginator rows={15} /> */}
        <Paginator
          first={lazyState.first}
          rows={pageSize}
          totalRecords={casesQuery.data?.count}
          template={PaginatorTemplate}
          // rowsPerPageOptions={[10, 20, 30, 50,100]}
          onPageChange={onPageChange}
        />

        <ChangeStatusSingleModal
          rowChanging={rowChanging}
          newStatus={newStatus}
          show={rowChanging !== null}
          onHide={() => {
            if (rowChanging === null) return;
            setRowChanging(null);
            setNewStatus(null);
          }}
          onSave={() => {
            if (rowChanging === null || newStatus === null) return;
            caseMutation.mutate({
              caseId: rowChanging.id,
              data: { status: newStatus },
            });
          }}
        />
        <ChangeStatusMultipleModal
          show={showMultipleModal}
          selection={selectedRows}
          newStatus={newStatusMultiple}
          onHide={() => {
            if (!showMultipleModal) return;
            setShowMultipleModal(false);
          }}
          onSave={() => {
            if (!showMultipleModal || !newStatusMultiple) return;
            caseMultipleMutation.mutate({
              caseIds: selectedRows.map((row) => row.id),
              status: newStatusMultiple,
            });
          }}
          loading={caseMultipleMutation.isPending}
        />
      </div>
    </Dashboard>
  );
}
