import React, { useRef, useState } from "react";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import { InputText } from "primereact/inputtext";
// import { IconField } from "primereact/iconfield";
// import { InputIcon } from "primereact/inputicon";
import { Dropdown } from "primereact/dropdown";
import {
  currencyFormat,
  formatCi,
  // getStatusChangeOptionsForRole,
} from "../utils";
// import { Link } from "react-router-dom";
import StatusTag from "../components/StatusTag";
// import { Button } from "primereact/button";
import { CaseStatus } from "../constants";
import { Paginator } from "primereact/paginator";
import Dashboard from "../Layout/Dashboard";

import { Button } from "primereact/button";
import {
  useReportMedicalCase,
  useReportMedicalCaseAll,
  useRequirementTypes,
} from "../hooks/queries";
import {
  ChangeStatusMultipleModal,
  ChangeStatusSingleModal,
} from "../components/StatusChangeModal";
import { usePatchMedicalCase } from "../hooks/mutations";
import { Toast } from "primereact/toast";
// import { StatusSelector } from "../components/StatusChangeSelector";
import { useMutation } from "@tanstack/react-query";
import { updateMultipleCasesStatus } from "../services/medicalCases";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { FloatLabel } from "primereact/floatlabel";
import { PaginatorTemplate } from "../Layout/Paginator/PaginatorTemplate";

/**
 * Dropdown to filter case patient sex
 */
// const sexFilterTemplate = (options) => (
//   <Dropdown
//     value={options.value}
//     options={GENDERS}
//     onChange={(e) => options.filterApplyCallback(e.value)}
//     placeholder="Seleccionar sexo"
//     className="p-column-filter min-w-min"
//     showClear
//     style={{ maxWidth: "10rem" }}
//   />
// );


/**
 * Template to show the amount in currency format
 */
const amountBodyTemplate = (rowData) => {
  return currencyFormat(rowData.amount ?? 0);
  // return currencyFormat(rowData.amount_usd ?? 0);
};

/**
 * Status tag for filter selector
 * @param {string} option
 */
const statusItemTemplate = (option) => {
  return <StatusTag value={option} />;
};

//   return <StatusTag value={option?.value} />;
// };

const caseStatusArray = Object.entries(CaseStatus.labels).map(([key, item]) => ({
  label: item,
  value: key,
})) 

/**
 * Status filter dropdown
 * @param {string[]} options
 */
const statusRowFilterTemplate = (options) => {
  return (
    <Dropdown
      value={options.value}
      // options={Object.values(CaseStatus)}
      // onChange={(e) => options.filterApplyCallback(e.value)}
      options={caseStatusArray}
      onChange={(e) => {
        options.filterApplyCallback(e.value)
      }}
      itemTemplate={statusItemTemplate}
      placeholder="Seleccionar status"
      className="p-column-filter min-w-min"
      showClear
    />
  );
};

/**
 * Medical cases table
 */
export default function MedicalCaseReportList() {
  // Get medical cases
  // const casesQuery = useMedicalCases();

  // Case detail link
  // const ViewDetailBodyTemplate = (row) => (
  //   <Link to={String(row.id)} className="p-button  text-sm p-2">
  //     <span className="p-button-icon p-button-icon-left pi pi-eye"></span>
  //     <span className="p-button-label">Ver</span>
  //   </Link>
  // );
  const [pageSize, setPageSize] = useState(20);
  const [lazyFilters, setLazyFilters] = useState({
    status: null,
  });

  const [lazyState, setLazyState] = useState({
    first: 1,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {},
  });
  // Get medical cases
  const casesQuery = useReportMedicalCase({
    page: lazyState.page,
    pageSize,
    filters: lazyFilters,
  });

  // Status change
  const [rowChanging, setRowChanging] = useState(null);
  const [newStatus, setNewStatus] = useState(null);
  const [newStatusMultiple, setNewStatusMultiple] = useState(null);
  const [showMultipleModal, setShowMultipleModal] = useState(false);
  const [date, setDate] = useState(null);
  const cols = [
    { field: "index", header: "Nro." },
    { field: "id", header: "ID" },
    { field: "atention_date", header: "Fecha Atención" },
    { field: "patient_ci", header: "CI" },
    { field: "patient_name", header: "Nombre Paciente" },
    { field: "medical_center_name", header: "Centro médico" },
    { field: "status", header: "Status" },
    { field: "amount_usd", header: "Monto ($)" },
    { field: "type_requirement_name", header: "Tipo de requerimiento" },
    { field: "requirements_name", header: "Requerimiento" },
  ];
  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toastRef = useRef(null);

  const caseAllQuery = useReportMedicalCaseAll({
    filters: lazyFilters,
    enabled: date !== null && date[0] !== null && date[1] !== null,
  });

  // Table multiple selection
  const [selectedRows, setSelectedRows] = useState(null);

  // Get requirement types
  const requirementTypesQuery = useRequirementTypes();

  // Update medical case from listing
  const caseMutation = usePatchMedicalCase({
    onSuccess: ({ type, message }) => {
      if (type === "success") {
        toastRef.current.show({
          severity: "success",
          summary: "Éxito",
          detail: message,
        });
        casesQuery.refetch();
        setRowChanging(null);
      } else {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: message,
        });
        setRowChanging(null);
      }
    },
  });

  // Update multiple cases status
  const caseMultipleMutation = useMutation({
    mutationFn: updateMultipleCasesStatus,
    onSuccess: ({ type, message }) => {
      if (type === "success") {
        toastRef.current.show({
          severity: "success",
          summary: "Éxito",
          detail: message,
        });
        casesQuery.refetch();
        setShowMultipleModal(false);
        setSelectedRows([]);
        setNewStatusMultiple(null);
      } else {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: message,
        });
        setShowMultipleModal(false);
      }
    },
  });

  // Global filters
  const [filters] = useState({
    // global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    created: { value: null, matchMode: FilterMatchMode.CONTAINS },
    id: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "patient_.sex": { value: null, matchMode: FilterMatchMode.EQUALS },
    requirement_type_names: { value: null, matchMode: FilterMatchMode.EQUALS },
    "holder_.ci": { value: null, matchMode: FilterMatchMode.CONTAINS },
    "patient_.ci": { value: null, matchMode: FilterMatchMode.CONTAINS },
    "patient_.name": { value: null, matchMode: FilterMatchMode.CONTAINS },
    requirement_names: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "patient_.birthdate": { value: null, matchMode: FilterMatchMode.CONTAINS },
    diagnosis_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    medical_center_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    subsidiary_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "patient_.phone": { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  // const [globalFilterValue, setGlobalFilterValue] = useState("");

  // const onGlobalFilterChange = (e) => {
  //   const value = e.target.value;
  //   let _filters = { ...filters };

  //   _filters["global"].value = value;

  //   setFilters(_filters);
  //   setGlobalFilterValue(value);
  // };


  /**
   * Status tag for table body
   */
  const statusBodyTemplate = (rowData) => (
    <StatusTag value={rowData.status} />
    // <StatusSelector
    //   currentStatus={rowData.status}
    //   selectedStatus={rowData.status}
    //   onChange={(e) => {
    //     setNewStatus(e.value);
    //     setRowChanging(rowData);
    //   }}
    // />
  );

  /**
   * Dropdown to filter case requirement type
   */
  const requirementTypeFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      loading={requirementTypesQuery.isLoading}
      disabled={requirementTypesQuery.isLoading}
      options={requirementTypesQuery.data ?? []}
      optionValue="name"
      optionLabel="name"
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder="Seleccionar tipo"
      className="p-column-filter min-w-min"
      showClear
      filter
      style={{ maxWidth: "10rem" }}
    />
  );

  const onPageChange = (e) => {
    console.log(e, "PageChange");
    setPageSize(e.rows);
    setLazyState({ ...lazyState, first: e.first, page: e.page + 1 });
  };

  const onFilter = (event) => {
    event["first"] = 0;
    setLazyState(event);
    setLazyFilters({
      ...lazyFilters,
      status: event.filters.status.value,
      id__contains: event.filters.id.value,
      holder__ci__icontains: event.filters["holder_.ci"].value,
      patient__ci__icontains: event.filters["patient_.ci"].value,
      patient_name__icontains: event.filters["patient_.name"].value,
      medical_center__name_icontains: event.filters.medical_center_name.value,
      requirements__requirement__type_requirement:
        event.filters.requirement_type_names.value,
    });
  };
  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default("l", 0);
        const img = new Image();

        img.src = "/citamed_logo.jpg";
        doc.addImage(img, "JPG", 10, 5, 50, 15);
        doc.setFontSize(14);
        selectedStatus &&
          doc.text(
            `Reporte de casos: ${selectedStatus
              .map((status) => CaseStatus.labels[status])
              .join(", ")
              .replace(/, ([^,]*)$/, ' y $1')}`,
            80,
            20
          );

        const date_report = date.map(
          (date) => date.toISOString().split("T")[0]
        );

        doc.text(`Del ${date_report.join(" al ")}`, 100, 30);

        doc.autoTable(exportColumns, caseAllQuery.data?.results ?? [], {
          startY: 50,
        });
        const total = caseAllQuery.data?.results.reduce(
          (acc, item) => acc + item.amount_usd,
          0
        );
        const totalFormat = currencyFormat(total);

        let first = doc.autoTable.previous;
        doc.autoTable(
          [
            { title: " ", dataKey: "label" },
            { title: " ", dataKey: "sum" },
          ],
          [{ label: "Total", sum: `$ ${totalFormat}` }],
          {
            startY: first.finalY + 15,
          }
        );
        doc.save("casos-medicos-citamed.pdf");
      });
    });
  };
  /**
   * Cases table header
   */
  const [selectedStatus, setSelectedStatus] = useState([]);

  const TableHeader = ({ newStatus, changeNewStatus, onStatusChangeClick }) => {
    return (
      <div className="full-w-header ">
        <div className="card flex justify-content-center mt-4">
          <FloatLabel>
            <label htmlFor="birth_date">Fecha de Atención</label>
            <Calendar
              locale="es"
              className="flex w-100"
              value={date}
              onChange={(e) => {
                setDate(e.value);
                // e.value to format date YYYY-MM-DD
                // const formattedDate = e.value.map(date => date.toISOString().split('T')[0]);
                // console.log(e.value[0]?.toISOString().split('T')[0], "DateRange")

                if (e.value != null && e.value.length === 2 && e.value[0] && e.value[1]) {
                  const range = e.value
                    .map((date) => date.toISOString().split("T")[0])
                    .join(",");
                  setLazyFilters({
                    ...lazyFilters,
                    // created_at__range: range,
                    atention_date__range: range,
                  });
                }
              }}
              selectionMode="range"
              hideOnRangeSelection
              readOnlyInput
              showButtonBar
              style={{ width: "250px" }}
            />
          </FloatLabel>
          <MultiSelect
            options={Object.entries(CaseStatus.labels).map(([key, value]) => ({
              label: value,
              value: key,
            }))}
            className="w-full md:w-20rem ml-3"
            optionValue="value"
            optionLabel="label"
            value={selectedStatus}
            onChange={(e) => {
              if(e.value != null){
                setLazyFilters({
                  ...lazyFilters,
                  status__in: e.value.join(","),
                });
              }

              setSelectedStatus(e.value);
            }}
            display="chip"
            placeholder="Seleccione Status"
            maxSelectedLabels={3}
          />

          <Button
            className="ml-3"
            type="button"
            icon="pi pi-file-pdf"
            severity="warning"
            rounded
            onClick={exportPdf}
            loading={caseAllQuery.isLoading}
            disabled={date === null || !(date[0] && date[1])}
            data-pr-tooltip="PDF"
          />
        </div>
        {/* <div>
          <Dropdown
            placeholder="Cambiar status en lote"
            options={getStatusChangeOptionsForRole()}
            itemTemplate={statusItemTemplate}
            valueTemplate={
              newStatus !== null && (
                <span>
                  Cambiar a: <StatusTag value={newStatus} />
                </span>
              )
            }
            value={newStatus}
            onChange={(e) => changeNewStatus(e.value)}
          />
          <Button
            label="Aplicar"
            className="ml-3"
            onClick={onStatusChangeClick}
          />
        </div> */}
      </div>
    );
  };

  return (
    <Dashboard headerTitle="Casos">
      <div className="card">
        <Toast ref={toastRef} position="top-center" />
        <DataTable
          value={casesQuery.data?.results ?? []}
          rows={10}
          dataKey="id"
          size={"small"}
          filters={filters}
          onFilter={onFilter}
          loading={casesQuery.isLoading}
          header={
            <TableHeader
              newStatus={newStatusMultiple}
              changeNewStatus={setNewStatusMultiple}
              onStatusChangeClick={() => setShowMultipleModal(true)}
            />
          }
          emptyMessage="No se hallaron casos."
          selectionMode="checkbox"
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          stripedRows
          scrollable
          removableSort
        >
          <Column selectionMode="multiple" headerStyle={{ width: "3rem" }} />
          <Column
            field="created"
            header="Fecha"
            filter
            filterPlaceholder="Buscar por fecha"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
          />
          <Column
            field="id"
            header="Caso #"
            sortable
            filter
            filterPlaceholder="Buscar por Nro. de Caso"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
          />
          <Column
            field="patient_.ci"
            header="CI Paciente"
            filter
            filterPlaceholder="Buscar por CI Paciente"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
            bodyClassName="readable-cell"
            body={(row) => formatCi(row.patient_.ci)}
          />
          <Column
            field="patient_.name"
            header="Nombre Paciente"
            filter
            filterPlaceholder="Buscar por Nombre"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
            bodyClassName="readable-cell"
          />

          <Column
            field="requirement_type_names"
            header="Tipo de requerimiento"
            filter
            filterElement={requirementTypeFilterTemplate}
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
          />
          <Column
            field="requirement_names"
            header="Requerimiento"
            filter
            filterPlaceholder="Buscar por Requerimiento"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
            bodyClassName="readable-cell"
          />
          {/* <Column
            field="amount"
            header="Monto (Bs.)"
            filter
            filterPlaceholder="Buscar por Monto"
            showFilterMenu={false}
            body={amountBodyTemplate}
            bodyClassName="readable-cell"
            sortable
          /> */}
          <Column
            field="amount_usd"
            header="Monto ($)"
            filter
            filterPlaceholder="Buscar por Monto"
            showFilterMenu={false}
            body={amountBodyTemplate}
            bodyClassName="readable-cell"
            sortable
          />

          <Column
            field="medical_center_name"
            header="Proveedor"
            filter
            filterPlaceholder="Buscar por Proveedor"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
            bodyClassName="readable-cell"
          />
          <Column
            field="status"
            header="Status"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
            body={statusBodyTemplate}
            filter
            filterElement={statusRowFilterTemplate}
            alignFrozen="right"
          />
          {/* <Column
            header="Acción"
            body={ViewDetailBodyTemplate}
            frozen
            alignFrozen="right"
          /> */}
        </DataTable>
        <Paginator
          first={lazyState.first}
          rows={pageSize}
          totalRecords={casesQuery.data?.count}
          rowsPerPageOptions={[10, 20, 30, 100]}
          onPageChange={onPageChange}
          template={PaginatorTemplate}
        />
        <ChangeStatusSingleModal
          rowChanging={rowChanging}
          newStatus={newStatus}
          show={rowChanging !== null}
          onHide={() => {
            if (rowChanging === null) return;
            setRowChanging(null);
            setNewStatus(null);
          }}
          onSave={() => {
            if (rowChanging === null || newStatus === null) return;
            caseMutation.mutate({
              caseId: rowChanging.id,
              data: { status: newStatus },
            });
          }}
        />
        <ChangeStatusMultipleModal
          show={showMultipleModal}
          selection={selectedRows}
          newStatus={newStatusMultiple}
          onHide={() => {
            if (!showMultipleModal) return;
            setShowMultipleModal(false);
          }}
          onSave={() => {
            if (!showMultipleModal || !newStatusMultiple) return;
            caseMultipleMutation.mutate({
              caseIds: selectedRows.map((row) => row.id),
              status: newStatusMultiple,
            });
          }}
          loading={caseMultipleMutation.isPending}
        />
      </div>
    </Dashboard>
  );
}
