import { BASE_URL } from "../constants";
import { getAuthHeader } from "../utils";


export async function getusersList() {
  try {
    const response = await fetch(BASE_URL + "users-list/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeader(),
      },
     
    });
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    const data = await response.json();
    return data;
  
  } catch (error) {
    console.error("Error creating advance: ", error);
    return { type: "error", message: "Error creando anticipo" };
  }
}

export async function getUsersClientList(userID) {
    const queryParams = new URLSearchParams();
    if (userID) {
        queryParams.append("user", userID);
    }
  try {
    const response = await fetch(`${BASE_URL}insurance/user-client/?${queryParams.toString()}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeader(),
      },
     
    });
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    const data = await response.json();
    return data;
  
  } catch (error) {
    console.error("Error creating advance: ", error);
    return { type: "error", message: "Error creando anticipo" };
  }
}

export async function saveUserClient({userID, clientsID}) {
    try {
        const response = await fetch(BASE_URL + "insurance/user-client/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...getAuthHeader(),
          },
          body: JSON.stringify({
            user: userID,
            company_client: clientsID,
          }),
        });
        if (!response.ok) {
          throw new Error(JSON.stringify(response));
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error creating advance: ", error);
        return { type: "error", message: "Error creando anticipo" };
      }
}