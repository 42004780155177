import { Tag } from "primereact/tag";

/**
 * Devuelve el color de fondo de la etiqueta de status de caso
 * @param {string} status 
 */
const getStatusBackground = (status) => {
  switch (status) {
    case "agotado":
        return "danger"
    case "disponible":
        return "success";
    default:
      return "transparent";
  }
}


/**
 * Etiqueta que muestra el status de un caso médico
 * @param {object} props
 * @param {string} props.value
 */
export default function FundStatusTag({ value, className }) {
  
    return <Tag className={className} severity={getStatusBackground(value)} value={value}  />;
}
