import "./App.css";

import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import Case from "./MedicalCases/Case";
// import CaseMasive from "./MedicalCases/CaseMasive";
import Person from "./Person/Person";
import { Navigate, RouterProvider, createBrowserRouter} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AdvanceListing from "./Advances/AdvanceListing";
import MedicalCenterAdvances from "./Advances/MedicalCenterAdvances";
import CreateAdvance from "./Advances/CreateAdvance";
import MedicalCentersListing from "./MedicalCenters/MedicalCentersListing";
import RequirementsListing from "./Requirements/RequirementsListing";
import CaseDetail from "./MedicalCases/CaseDetail";
import InvoiceList from "./Invoices/InvoiceList";
import CreateInvoice from "./Invoices/CreateInvoice";
import CreateMedicalCenter from "./MedicalCenters/CreateMedicalCenter";
import Login from "./Login/Login";
import Users from "./ManageUsers/Users";
import UsersClient from "./ManageUsers/UsersClient";
import MedicalCasesListing from "./MedicalCases/MedicalCasesListing";
// import PageConstruction from "./PageConstruction/PageConstruction";
import BaremosListing from "./Baremos/BaremosListing";
import EditMedicalCenter from "./MedicalCenters/EditMedicalCenter";
import EditBaremo from "./Baremos/EditBaremo";
import CreateBaremo from "./Baremos/CreateBaremo";
import Logout from "./Logout/Logout";
import MedicalCasesSubscribeList from "./MedicalCases/Subscribe/MedicalCasesSubscribeList";
import { ROUTE_ALLOWED_ROLES } from "./constants";
import MedicalCaseReportList from "./Reports/MedicalCaseReportList";
import Maintenance from "./Maintenance/Maintenance";
import { addLocale } from 'primereact/api';
import ErrorPage from "./NotFound/ErrorPage";

addLocale('es', {
  firstDayOfWeek: 1,
  dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
  dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
  dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
  monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
  monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
  today: 'Hoy',
  clear: 'Limpiar',
});
      

/**
 * Redirects to a page if the user is not authenticated
 * 
 * Taken from https://stackoverflow.com/a/69907150/13784002
 * 
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {string} props.path Route to access
 * @param {string} props.redirectTo Route to redirect if user is not authenticated or authorized
 */
function ProtectedRoute({ children, path, redirectTo="/login" }) {
  if (!path) {
    return <Navigate to={redirectTo} />;
  }

  const isAuthenticated = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const isAdmin = localStorage.getItem("isAdmin");
  // cast string to boolean
  // if (isAuthenticated && isAdmin==="false" ) {
  //   return <Navigate to="/mantenimiento" />;
  // }

  if(isAdmin==="true") {
    return children;
  }

  // if (role === UserRole.MASTER) {
  //   return children;
  // }
  

  const roles = ROUTE_ALLOWED_ROLES[path] || [];

  return isAuthenticated && roles.includes(role)
    ? children
    : <Navigate to={redirectTo} />;

}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/usuarios",
    element: (
      <ProtectedRoute path="/usuarios">
        <Users />
      </ProtectedRoute>
    ),
  },
  {
    path: "/usuarios/cliente",
    element: (
      <ProtectedRoute path="/usuarios">
        <UsersClient />
      </ProtectedRoute>
    ),
  },
  {
    path: "/persona/editar/:personId",
    element: (
      <ProtectedRoute path="/casos/nuevo">
        <Person/>
      </ProtectedRoute>
    ),
  },
  {
    path: "/casos",
    element: (
      <ProtectedRoute path="/casos">
        <MedicalCasesListing />
      </ProtectedRoute>
    ),
  },
  {
    path: "/casos/nuevo",
    element: (
      <ProtectedRoute path="/casos/nuevo">
        <Case/>
      </ProtectedRoute>
    ),
  },
  {
    path: "/casos/nuevo/:caseId",
    element: (
      <ProtectedRoute path="/casos/nuevo">
        <Case/>
      </ProtectedRoute>
    ),
  },
  // {
  //   path: "/casos/masivo/nuevo",
  //   element: (
  //     <ProtectedRoute path="/casos/nuevo">
  //       <CaseMasive/>
  //     </ProtectedRoute>
  //   ),
  // },
  {
    path: "/casos/:caseId",
    element: (
      <ProtectedRoute path="/casos/:caseId">
        <CaseDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: "/casos/por-suscribir",
    element: (
      <ProtectedRoute path="/casos/por-suscribir">
        <MedicalCasesSubscribeList />
      </ProtectedRoute>
    ),
  },
  {
    path: "/casos/por-suscribir/:caseId",
    element: (
      <ProtectedRoute path="/casos/por-suscribir/:caseId">
        <CaseDetail backButtonUrl="/casos/por-suscribir"/>
      </ProtectedRoute>
    ),
  },
  {
    path: "/anticipos",
    element: (
      <ProtectedRoute path="/anticipos">
        <AdvanceListing />
      </ProtectedRoute>
    ),
  },
  {
    path: "/anticipos/nuevo",
    element: (
      <ProtectedRoute path="/anticipos/nuevo">
        <CreateAdvance />
      </ProtectedRoute>
    ),
  },
  {
    path: "/anticipos/proveedores/:centerId",
    element: (
      <ProtectedRoute path="/anticipos/proveedores/:centerId">
        <MedicalCenterAdvances />
      </ProtectedRoute>
    ),
  },
  {
    path: "/baremos",
    element: (
      <ProtectedRoute path="/baremos">
        <BaremosListing />
      </ProtectedRoute>
    ),
  },
  {
    path: "/baremos/nuevo",
    element: (
      <ProtectedRoute path="/baremos/nuevo">
        <CreateBaremo />
      </ProtectedRoute>
    ),
  },
  {
    path: "/baremos/:baremoId",
    element: (
      <ProtectedRoute path="/baremos/:baremoId">
        <EditBaremo />
      </ProtectedRoute>
    ),
  },
  {
    path: "/proveedores",
    element: (
      <ProtectedRoute path="/proveedores">
        <MedicalCentersListing />
      </ProtectedRoute>
    ),
  },
  {
    path: "/proveedores/nuevo",
    element: (
      <ProtectedRoute path="/proveedores/nuevo">
        <CreateMedicalCenter />
      </ProtectedRoute>
    ),
  },
  {
    path: "/proveedores/editar/:centerId",
    element: (
      <ProtectedRoute path="/proveedores/editar/:centerId">
        <EditMedicalCenter />
      </ProtectedRoute>
    ),
  },
  {
    path: "/requerimientos",
    element: (
      <ProtectedRoute path="/requerimientos">
        <RequirementsListing />
      </ProtectedRoute>
    ),
  },
  {
    path: "/centro-medico/facturas",
    element: (
      <ProtectedRoute path="/centro-medico/facturas">
        <InvoiceList />
      </ProtectedRoute>
    ),
  },
  {
    path: "/centro-medico/facturas/nueva",
    element: (
      <ProtectedRoute path="/centro-medico/facturas/nueva">
        <CreateInvoice />
      </ProtectedRoute>
    ),
  },
  {
    path: "/centro-medico/servicios",
    element: (
      <ProtectedRoute path="/centro-medico/servicios">
        <CreateMedicalCenter />
      </ProtectedRoute>
    ),
  },
  {
    path: "/reportes/casos-medicos",
    element: (
      <ProtectedRoute path="/casos">
        <MedicalCaseReportList />
      </ProtectedRoute>
    ),
  },
  {
    path: "/mantenimiento",
    element: <Maintenance />,
  },
]);

const queryClient = new QueryClient();

function App() {
  // useEffect(() => {
  //   const updateServiceWorker = (registration) => {
  //     if (registration.waiting) {
  //       registration.waiting.postMessage({ type: 'SKIP_WAITING' });
  //       registration.waiting.addEventListener('statechange', (event) => {
  //         console.log("UpdateServiceWorker", event);
  //         if (event.target.state === 'activated') {
  //           console.log("activated");
  //           // window.location.reload();
  //         }
  //       });
  //     }
  //   };

  //   if ('serviceWorker' in navigator) {
  //     navigator.serviceWorker.register('/service-worker.js').then((registration) => {
  //       console.log("firstInstall", registration);
  //       registration.addEventListener('updatefound', () => {
  //         console.log(registration)
  //         updateServiceWorker(registration);
  //       });
  //     });
  //   }
  // }, []);
  return (
    <PrimeReactProvider>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </PrimeReactProvider>
  );
}

export default App;
