import React, {useEffect} from "react";

import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import {AuthCheck} from "../services/auth";

export default function TemplateDemo({ setIsActive, 
  // setIsActiveMobile 
  headerTitle
}) {

  const [isAuth, setIsAuth ] = React.useState(false);
  
  useEffect(() => {
    const checkAuth = async () => {
      const auth = await AuthCheck();
      console.log(auth);  
      setIsAuth(auth);
    };
    checkAuth();
  }, [isAuth]);

  // const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const startContent = (
    <>
      {isAuth && (

        <Button icon="pi pi-bars" text raised onClick={setIsActive}></Button>
      )}

      {/* {!isMobile && (
        <Button icon="pi pi-bars" text raised onClick={setIsActive}></Button>
      )}
      {isMobile && (
        <Button
          icon="pi pi-bars"
          text
          raised
          onClick={setIsActiveMobile}
        ></Button>
      )} */}
    </>
    // <div className="flex align-items-center gap-2">
    //   <Avatar
    //     style={{width: "11rem", paddingRight: "1rem"}}
    //     size="large"
    //     image="https://
    //   />
    // </div>
  );

  const centerContent = (
    <>
      <span className="title font-bold	text-700">{headerTitle ?? ""}</span>
      {/* headerTitle ??  */}
      {/* <img alt="logo" className="hidden md:block" src="/logo-la-mundial.png" height="40"></img>
      <img alt="logo" className="md:hidden" src="/isotipo-la-mundial.png" height="40"></img> */}
    </>
  );
  const endContent = (
    <>
      <img
        alt="logo"
        src="/logo.png"
        height="40"
        className="hidden md:block mr-2"
      ></img>
      {/* <img
        alt="logo"
        src="/isologo-fin.png"
        height="40"
        className="md:hidden mr-2"
      ></img> */}
    </>
    // if
  );

  return (
    <div className="card">
      {/* <Menubar model={items} start={start} end={end} /> */}
      <Toolbar start={startContent} center={centerContent} end={endContent} />
    </div>
  );
}
