
import { BASE_URL } from "../constants";

export async function fetchDashboardVersion() {
  try {
    const response = await fetch(`${BASE_URL}insurance/dashboard-version/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching dependency:", error);
    throw error;
  }
}
