import { getAuthHeader } from "../utils";
import { BASE_URL } from "../constants";



export async function getDependency({dniCode, dniNumber}) {
    const queryParams = new URLSearchParams({ code_ci: dniCode, ci_number:dniNumber }).toString();
    const url = `${BASE_URL}insurance/get-dependency/?${queryParams}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                ...getAuthHeader(),
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching dependency:', error);
        throw error;
    }
}


/**
 * Gets list of holders matching CI from left to right
 * @param {string | undefined} ci 
 */
export async function getHoldersRMSCI(ciType, ciNumber) {
  const data = await getDependency({dniCode: ciType, dniNumber: ciNumber});
  console.log(data,"data")
  return data.results
//   return data.results.filter((person) =>  person.correl_aseg == 0);
}


// export async function updateCaseRequirementsDone({ caseId, doneRequirements }) {
//   try {
//     const response = await fetch(`${BASE_URL}insurance/medical-case/${caseId}/`, {
//       method: "PATCH",
//       headers: {
//         "Content-Type": "application/json",
//         ...getAuthHeader(),
//       },
//       body: JSON.stringify({ requirements: doneRequirements, status: CaseStatus.ATENDIDO }),
//     });
//     if (!response.ok) {
//       throw new Error(JSON.stringify(response));
//     }
//     return { type: "success", message: "Requerimientos marcados como completados." };
//   } catch (error) {
//     console.error("Error updating case: ", error);
//     return { type: "error", message: "Error marcando requerimientos como completados" };
//   }
// }