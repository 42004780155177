import React from 'react';

function Maintenance() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
    <img src="/mantenimiento.jpg" alt="Mantenimiento" style={{ width: '80%', height: 'auto' }} />
    </div>
  );
}

export default Maintenance;
