
import { AutoComplete } from "primereact/autocomplete";
import { useRef } from "react";

/**
 * Text input that shows selectable suggestions in an overlay panel
 * @param {object} props
 * @param {string} props.inputName Name and id of the text input
 * @param {string} props.inputValue Value of the text input
 * @param {boolean} props.inputDisabled Whether the text input is disabled
 * @param {import("primereact/keyfilter").KeyFilterType} props.keyfilter Keyfilter for the text input
 * @param {number} props.maxLength Maximum length of the input
 * @param {(e: React.ChangeEvent) => void} props.onTextChange Function to call when the text input value changes
 * @param {object} props.query Query object from useQuery
 * @param {string} props.notFoundBtnLabel Label for the button to create a item when no results are found
 * @param {() => void} props.notFoundBtnClick Function to call when the button to create a item is clicked
 * @param {(item: any) => JSX.Element} props.itemTemplate Function to render each item in the list
 * @param {(e: React.KeyboardEvent) => void} props.onSearchEnter Function to call when the enter key is pressed on text input
 */
export default function InputWithSuggestions({
  inputName,
  inputValue,
  keyfilter,
  maxLength,
  onTextChange,
  query,
  notFoundBtnLabel,
  notFoundBtnClick,
  itemTemplate,
  overlayRef,
  onSearchEnter,
  onCompleteMethod,
  suggestions,
  inputDisabled
}) {
  const inputRef = useRef(null);
  
  return (
    <div className=" p-inputgroup flex ">
      <AutoComplete
        ref={inputRef}
        field="label"
        className="w-full"
        id={inputName}
        name={inputName}
        value={inputValue}
        suggestions={suggestions}
        completeMethod={onCompleteMethod}
        onPaste={onCompleteMethod}
        // keyboardType="numeric"
        onChange={(e) => {
          onTextChange(e);
          // search(e);
        }}
        disabled={inputDisabled}
      />
    </div>
  );
}
