import {
  useRef,
  useState,
  // useEffect
} from "react";
import { InputText } from "primereact/inputtext";
import { useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { useForm } from "@tanstack/react-form";
import { useStore } from "@tanstack/react-form";
import { Skeleton } from "primereact/skeleton";

import "./Person.css";
import FormSubHeading from "../components/FormSubHeading";
import { useMutation, useQuery } from "@tanstack/react-query";
import { GENDERS, PHONE_CODES } from "../constants";
import { getHoldersMatchingCI, getPeopleMatchingCI } from "../services/people";
import { SelectButton } from "primereact/selectbutton";
import InputWithSuggestions from "../components/InputWithSuggestions";
import { Divider } from "primereact/divider";
import VSieteDateInput from "../components/VSieteDateInput";
import { getDependency, getHoldersRMSCI } from "../services/rms";
import { getPerson, updatePerson } from "../services/people";

/**
 * @typedef API_Person Structure of the Person object returned by the API
 * @property {number} id
 * @property {string} ci
 * @property {string} name
 * @property {"M" | "F"} sex
 * @property {string} birthdate
 * @property {string} phone
 * @property {string} subsidiary
 * @property {boolean} is_holder
 * @property {string | null} holder
 */

function FieldInfo({ field }) {
  return (
    <>
      {field.state.meta.isTouched && field.state.meta.errors.length ? (
        <small className="p-error">{field.state.meta.errors.join(",")}</small>
      ) : null}
      {/* {field.state.meta.isValidating ? 'Validating...' : null} */}
    </>
  );
}

const INITIAL_PATIENT_FORM = {
  ciType: "V",
  ci: "",
  name: "",
  sex: null,
  birthDate: "",
  phoneCode: "58412",
  phoneNumber: "",
  diagnosis: null,
  client: null,
  subsidiary: null,
  isHolder: true,
  shouldCreatePatient: false,
  minorId: "",
  isMinor: false,
};

const INITIAL_HOLDER_FORM = {
  holderCiType: "V",
  holderCi: "",
  ciType: "V",
  ci: "",
  name: "",
  sex: null,
  birthDate: "",
  birthDay: null,
  birthMonth: null,
  birthYear: null,
  phoneCode: "58412",
  phoneNumber: "",
  condition: "A",
  optionalPhoneCode: "58412",
  optionalPhoneNumber: "",
  isMinor: false,
  patient: INITIAL_PATIENT_FORM,
};

/**
 * Person search item template
 * @param {(item: API_Person) => void} onClick
 */
function createSearchItemTemplate(onClick) {
  /** @param {API_Person} item */
  return (item) => (
    <>
      <span className="py-1" onClick={() => onClick(item)}>
        {item.ci} - {item.name}
      </span>
      <Divider />
    </>
  );
}

/**
 * Formulario para crear un nuevo caso médico
 */
const FormComponent = () => {
  const { personId } = useParams();

  const { data, isLoading } = useQuery({
    queryKey: ["person", personId],
    queryFn: () => getPerson(personId),
    enabled: !!personId,
  });

  const updatePersonMutation = useMutation({
    mutationFn: updatePerson,
    onSuccess: (data) => {
      if (data.type === "success") {
        toast.current.show({
          severity: "success",
          summary: "Éxito",
          detail: data.message,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: data.message,
        });
      }
    },
  });

  const form = useForm({
    initialValues: INITIAL_HOLDER_FORM,
    defaultValues: {
      holderCiType: data?.holder_?.ci[0] ?? INITIAL_HOLDER_FORM.holderCiType,
      holderCi:
        data?.holder_?.ci.replace(/\D/g, "") ?? INITIAL_HOLDER_FORM.holderCi,
      ciType: data?.is_minor ? "M" : "V",
      ci: data?.ci.replace(/\D/g, "") ?? INITIAL_HOLDER_FORM.ci,
      name: data?.name ?? INITIAL_HOLDER_FORM.name,
      sex: data?.sex ?? INITIAL_HOLDER_FORM.sex,
      phoneCode: data?.phone.slice(0, 5) ?? INITIAL_HOLDER_FORM.phoneCode,
      phoneNumber: data?.phone.slice(5) ?? INITIAL_HOLDER_FORM.phoneNumber,
      patient: INITIAL_PATIENT_FORM,
      birthDate: data?.birthdate.split("-").reverse().join("/"),
      optionalPhoneCode:
        data?.optional_phone.slice(0, 5) ??
        INITIAL_HOLDER_FORM.optionalPhoneCode,
      optionalPhoneNumber:
        data?.optional_phone.slice(5) ??
        INITIAL_HOLDER_FORM.optionalPhoneNumber,
      minorId: data?.minor_id ?? "",
    },
    validators: {
      onMount: ({ value }) => {
        return {
          fields: {
            ci: value.ci ? undefined : "Cédula es requerida",
          },
        };
      },
      onChange: ({ value }) => {
        console.log(value);

        return {
          fields: {
            sex: value.sex ? undefined : "Seleccione un sexo",
            name: value.name ? undefined : "Nombre completo es requerido",
            birthDate:
              value.birthDate.length === 10
                ? undefined
                : "Fecha de nacimiento es requerida",
            condition: value.condition ? undefined : "Condicion es requerida",
            phoneNumber:
              value.phoneNumber.length === 7
                ? undefined
                : "Número de teléfono es requerido",
          },
        };
      },
    },
    onSubmit: async ({ value }) => {
      // Do something with form data
      console.log("DAta value", value);
      updatePersonMutation.mutate({
        id: personId,
        data: value,
      });
    },
  });

  // Form state
  const [patientForm, setPatientForm] = useState(INITIAL_PATIENT_FORM);
  const [holderForm, setHolderForm] = useState(INITIAL_HOLDER_FORM);
  // const [dependency, setDependency] = useState([
  //   { name: "1", code: "1" },
  //   { name: "2", code: "2" },
  //   { name: "3", code: "3" },
  //   { name: "4", code: "4" },
  //   { name: "5", code: "5" },
  // ]);

  // Get holder by CI
  //   const holderQuery = useQuery({
  //   queryKey: ["holder", holderForm.ciType + holderForm.ci],
  //   queryFn: () => getHoldersMatchingCI(holderForm.ciType + holderForm.ci),
  //   enabled: holderForm.ci.length >= 4,
  // });

  const ciType = useStore(form.store, (state) => state.values.ciType);
  const holderCi = useStore(form.store, (state) => state.values.holderCi);
  const holderCiType = useStore(
    form.store,
    (state) => state.values.holderCiType
  );

  // Get holder by CI in rms
  const holderRMSQuery = useQuery({
    queryKey: ["holder-rms", holderCiType + holderCi],
    queryFn: () => getHoldersRMSCI(holderCiType, holderCi),
    // enabled: holderForm.ci.length >= 4,
    enabled: false,
  });

  const holderQuery = useQuery({
    queryKey: ["holder", holderCiType + holderCi],
    queryFn: () => getHoldersMatchingCI(holderCiType + holderCi),
    // enabled: holderCi?.length >= 4,
    enabled: false,
  });



  const {data:dependencies} = useQuery({
    queryKey: ["patient-dependency", patientForm.minorId],
    queryFn: () => getDependency({ dniCode: holderCiType, dniNumber: holderCi }),
    enabled: !!holderCi
  });

  const dependency = dependencies?.results.filter((person) => person.correl_aseg !== 0)
  .map((item) => ({
    ...item,
    name: `${item.nombre}`,
    code: item.correl_aseg,
  }));
  // const [holderResult, setHolderResult] = useState([]);
  const [patientResult, setPatientResult] = useState([]);
  // Get patient by CI
  const patientQuery = useQuery({
    queryKey: ["patient", patientForm.ciType + patientForm.ci],
    queryFn: () => getPeopleMatchingCI(patientForm.ciType + patientForm.ci),
    enabled: patientForm.ci.length >= 4,
  });

  // const getDependencyMutation = useMutation({
  //   mutationFn: getDependency,
  //   onSuccess: (data) => {
  //     if (!data.results) {
  //       setDependency([]);
  //       // setHolderList({data: []});
  //       return;
  //     }
  //     const dependency = data.results
  //       .filter((dep) => dep.correl_aseg !== 0)
  //       .map((dep) => {
  //         return { name: dep.nombre, code: dep.correl_aseg };
  //       });
  //     // const holder = data.results.filter((dep)=> dep.correl_aseg === 0);
  //     setDependency(dependency);
  //     // setHolderList({data: holder});
  //   },
  // });

  // Form message toast
  const toast = useRef(null);

  // Patient and holder name inputs
  const holderNameRef = useRef(null);
  // const patientNameRef = useRef(null);

  // Search overlays
  const holderSearchRef = useRef(null);

  /**
   * Key up event for holder search
   * @param {KeyboardEvent} e
   */
  const onHolderSearchEnter = (e) => {
    if (e.key === "Escape") {
      holderSearchRef.current.hide();
    }
    if (e.key === "Enter") {
      // const ci = holderForm.ciType + holderForm.ci;
      // if (
      //   !holderQuery.data ||
      //   !holderQuery.data.length ||
      //   ci !== holderQuery.data[0].ci
      // ) {
      //   setHolderForm({ ...holderForm, shouldCreateHolder: true });
      //   holderSearchRef.current.hide();
      //   // Wait 50ms for holderNameRef to be set
      //   setTimeout(() => {
      //     holderNameRef.current.focus();
      //   }, 50);
      //   return;
      // }
      // selectHolder(holderQuery.data[0]);
    }
  };

  /**
   * Key up event for patient search
   * @param {KeyboardEvent} e
   */
  // const onPatientSearchEnter = (e) => {
  //   if (patientSelected) return;

  //   if (e.key === "Escape") {
  //     patientSearchRef.current.hide();
  //   }
  //   if (e.key === "Enter") {
  //     if (!patientQuery.data || !patientQuery.data.length) {
  //       setPatientForm({ ...patientForm, shouldCreatePatient: true });
  //       patientSearchRef.current.hide();
  //       patientNameRef.current.focus();
  //     } else {
  //       selectPatient(patientQuery.data[0]);
  //     }
  //   }
  // };

  /**
   * Sets form state from a selected patient
   * @param {API_Person} holder
   */
  const selectHolder = (holder) => {
    setHolderForm({
      ciType: holderForm.ciType,
      ci: holderForm.ci,
      // sex: holder.sex,
      // birthDate: holder.birthdate.split("-").reverse().join("/"),
      name: holder.nombre,
      // phoneCode: holder.phone.slice(0, 5),
      // phoneNumber: holder.phone.slice(5),
      shouldCreateHolder: false,
    });
    setPatientForm({ ...patientForm, isHolder: true });
    // setHolderLocked(true);
    holderSearchRef.current.hide();
  };

  const search = async (e) => {
    if (e.query && /\D/.test(e.query)) {
      form.setFieldValue("ci", e.query.replace(/\D/g, ""));
      e.query = e.query.replace(/\D/g, "");
      search(e);
      return;
    }
    // const response = await getHoldersMatchingCI(holderCiType + e.query);
    // getDependenciesRMSCIMutation.mutate();
    // const responseRms = await getHoldersRMSCI(holderCiType, e.query);

    // const changeHolder = responseRms
    //   .filter((person) => person.correl_aseg === 0)
    //   .map((item) => ({
    //     ...item,
    //     label: `${item.cedrif_aseg} - ${item.nombre}`,
    //     value: item.cedrif_aseg,
    //   }));

    // const dependencies = responseRms
    //   .filter((person) => person.correl_aseg !== 0)
    //   .map((item) => ({
    //     ...item,
    //     name: `${item.nombre}`,
    //     code: item.correl_aseg,
    //   }));
    // setDependency(dependencies);
    // setHolderResult(changeHolder);
  };

  const searchPatient = async (e) => {
    if (e.query && /\D/.test(e.query)) {
      form.setFieldValue("patient.ci", e.query.replace(/\D/g, ""));
      e.query = e.query.replace(/\D/g, "");
      searchPatient(e);
      return;
    }
    // getDependenciesRMSCIMutation.mutate();
    const responseRms = await getHoldersRMSCI(ciType, e.query);
    const changePatient = responseRms.map((item) => ({
      ...item,
      label: `${item.cedrif_aseg} - ${item.nombre}`,
      value: item.cedrif_aseg,
    }));
    setPatientResult(changePatient);
  };
  if (isLoading) {
    return (
      <div className="w-full md:w-6 p-3">
        <Skeleton className="mb-2"></Skeleton>
        <Skeleton width="10rem" className="mb-2"></Skeleton>
        <Skeleton width="5rem" className="mb-2"></Skeleton>
        <Skeleton height="2rem" className="mb-2"></Skeleton>
        <Skeleton width="10rem" height="4rem"></Skeleton>
      </div>
    );
  }
  return (
    <div className="p-fluid">
      <Toast ref={toast} position="top-center" />
      <form>
        {/* DATOS DEL TITULAR */}

        <div>
          <div>
            <FormSubHeading title="Datos del Titular" />
            <div className="field-with-select mb-2">
              <form.Field name="holderCiType">
                {(field) => (
                  <Dropdown
                    id={field.name}
                    name={field.name}
                    options={[{ label: "V", value: "V" }]}
                    placeholder="Tipo"
                    value={field.state.value}
                    onChange={(e) => field.handleChange(e.value)}
                    required
                    disabled={true}
                  />
                )}
              </form.Field>
              <form.Field name="holderCi">
                {(field) => (
                  <InputWithSuggestions
                    inputName={field.name}
                    inputValue={field.state.value}
                    inputDisabled={true}
                    keyfilter="pint"
                    maxLength={9}
                    onCompleteMethod={search}
                    suggestions={[]}
                    onTextChange={(e) => {
                      if (e.value && e.value.hasOwnProperty("value")) {
                        field.handleChange(e.value.value);

                        // form.setFieldValue("name", e.value.name);
                        // form.setFieldValue("sex", e.value.sex);
                        // form.setFieldValue(
                        //   "birthDate",
                        //   e.value.birthdate.split("-").reverse().join("/")
                        // );
                        // form.setFieldValue("condition", e.value.condition);

                        // const phone = e.value.phone;
                        // form.setFieldValue("phoneCode", phone.slice(0, 5));
                        // form.setFieldValue("phoneNumber", phone.slice(5));

                        form.validateSync();
                        // getDependencyMutation.mutate({
                        //   dniCode: ciType,
                        //   dniNumber: holderCi,
                        // });
                        return;
                      }
                      field.handleChange(e.value);
                    }}
                    query={holderRMSQuery}
                    notFoundBtnLabel="Crear registro de asegurado"
                    notFoundBtnClick={() => {}}
                    itemTemplate={createSearchItemTemplate(selectHolder)}
                    overlayRef={holderSearchRef}
                    onSearchEnter={onHolderSearchEnter}
                  />
                )}
              </form.Field>
            </div>
          </div>

          <FormSubHeading title="Datos del Paciente" />

          <div className="p-field">
            <label htmlFor="holderCi">Cédula</label>
            <div className="field-with-select">
              <form.Field name="ciType">
                {(field) => (
                  <Dropdown
                    id={field.name}
                    name={field.name}
                    options={[
                      { label: "V", value: "V" },
                      { label: "MENOR", value: "M" },
                    ]}
                    placeholder="Tipo"
                    value={field.state.value}
                    onChange={(e) => field.handleChange(e.value)}
                    required
                  />
                )}
              </form.Field>

              {ciType === "M" ? (
                <form.Field name="minorId">
                  {(field) => (
                    <Dropdown
                      id={field.name}
                      name={field.name}
                      options={dependency}
                      optionLabel="name"
                      optionValue="code"
                      placeholder="Código"
                      value={field.state.value}
                      onChange={(e) => {
                        field.handleChange(e.value);
                        const dependencySelect = dependency.find(
                          (item) => item.code === e.value
                        );
                        if (dependencySelect) {
                          form.setFieldValue("name", dependencySelect.name);
                        }
                      }}
                    />
                  )}
                </form.Field>
              ) : null}

              {ciType === "V" ? (
                <form.Field name="ci">
                  {(field) => (
                    <InputWithSuggestions
                      inputName={field.name}
                      inputValue={field.state.value}
                      keyfilter="pint"
                      maxLength={9}
                      onCompleteMethod={searchPatient}
                      suggestions={patientResult}
                      onTextChange={(e) => {
                        if (e.value && e.value.hasOwnProperty("value")) {
                          field.handleChange(e.value.value);
                          form.setFieldValue("name", e.value.nombre);
                          // form.setFieldValue("name", e.value.name);
                          // form.setFieldValue("sex", e.value.sex);
                          // form.setFieldValue(
                          //   "birthDate",
                          //   e.value.birthdate.split("-").reverse().join("/")
                          // );
                          // form.setFieldValue("condition", e.value.condition);
                          // form.setFieldValue("phoneNumber", e.value)
                          // const phone = e.value.phone;
                          // form.setFieldValue("phoneCode", phone.slice(0, 5));
                          // form.setFieldValue("phoneNumber", phone.slice(5));
                          // phone
                          // form.validateSync();
                          // getDependencyMutation.mutate({
                          //   dniCode: ciType,
                          //   dniNumber: holderCi,
                          // });

                          return;
                        }
                        field.handleChange(e.value);
                      }}
                      query={holderRMSQuery}
                      notFoundBtnLabel="Crear registro de asegurado"
                      notFoundBtnClick={() => {}}
                      itemTemplate={createSearchItemTemplate(selectHolder)}
                      overlayRef={holderSearchRef}
                      onSearchEnter={onHolderSearchEnter}
                    />
                  )}
                </form.Field>
              ) : null}
            </div>
          </div>

          <div className="p-field">
            {/* Holder personal data */}
            <form.Field
              name="name"
              children={(field) => (
                <div className="p-field mt-2">
                  <label htmlFor="holderName">Nombre completo</label>
                  <InputText
                    id="holderName"
                    name={field.name}
                    value={field.state.value}
                    onChange={(e) => field.handleChange(e.target.value)}
                    // setHolderForm({ ...holderForm, name: e.target.value })
                    invalid={field.state.meta.errors.length > 0}
                    disabled={holderQuery.isLoading}
                    ref={holderNameRef}
                  />
                  <FieldInfo field={field} />
                </div>
              )}
            />

            {/* SEXO */}
            <form.Field
              name="sex"
              children={(field) => (
                <div className="p-field">
                  <label htmlFor="holderSex">Sexo</label>
                  <SelectButton
                    id="holderSex"
                    name="holderSex"
                    value={field.state.value}
                    onChange={(e) => field.handleChange(e.target.value)}
                    // setHolderForm({ ...holderForm, sex: e.value })
                    options={GENDERS}
                    disabled={holderQuery.isLoading}
                    invalid={field.state.meta.errors.length > 0}
                  />
                  <FieldInfo field={field} />
                </div>
              )}
            />

            <form.Field name="birthDate">
              {(field) => (
                <div className="p-field">
                  <span>Fecha de nacimiento</span>
                  <VSieteDateInput
                    name="holderBirthdate"
                    value={field.state.value}
                    setValue={(value) => field.handleChange(value)}
                    // setHolderForm({ ...holderForm, birthDate: value })
                    disabled={holderQuery.isLoading}
                    invalid={field.state.meta.errors.length > 0}
                  />
                </div>
              )}
            </form.Field>

            {/* <form.Field name="condition">
              {(field) => (
                <div className="p-field">
                  <label htmlFor="holderCondition">Condición</label>
                  <SelectButton
                    id="holderCondition"
                    name="holderCondition"
                    className="select-sex"
                    value={field.state.value}
                    onChange={(e) => field.handleChange(e.target.value)}
                    options={CONDITIONS}
                    invalid={field.state.meta.errors.length > 0}
                  />
                </div>
              )}
            </form.Field> */}

            <div className="p-field">
              <label htmlFor="phoneNumber">Número de teléfono</label>
              <div className="field-with-select">
                <form.Field name="phoneCode">
                  {(field) => (
                    <Dropdown
                      id="phoneCode"
                      name="phoneCode"
                      options={PHONE_CODES}
                      placeholder="Código"
                      value={field.state.value}
                      onChange={(e) => field.handleChange(e.value)}
                      disabled={patientQuery.isLoading}
                    />
                  )}
                </form.Field>

                <form.Field name="phoneNumber">
                  {(field) => (
                    <InputText
                      id="phoneNumber"
                      name="phoneNumber"
                      inputMode="numeric"
                      keyfilter="pint"
                      maxLength={7}
                      value={field.state.value}
                      onChange={(e) => field.handleChange(e.target.value)}
                      onPaste={(e) => {
                        const text = e.clipboardData.getData("text/plain");
                        const newText = text.replace(/\D/g, "");
                        if (newText.length === 11) {
                          const phoneCode = newText.slice(0, 4);
                          const result = PHONE_CODES.find((item) => {
                            return (
                              item.value === phoneCode ||
                              item.label === phoneCode
                            );
                          });

                          if (result) {
                            form.setFieldValue("phoneCode", result.value);
                            form.setFieldValue("phoneNumber", newText.slice(4));
                          }
                          form.setFieldValue("phoneNumber", newText.slice(4));
                        }
                      }}
                      disabled={patientQuery.isLoading}
                    />
                  )}
                </form.Field>
              </div>
            </div>

            <div className="p-field">
              <label htmlFor="phoneNumber">Número de teléfono (opcional)</label>
              <div className="field-with-select">
                <form.Field name="optionalPhoneCode">
                  {(field) => (
                    <Dropdown
                      id="phoneCode"
                      name="phoneCode"
                      options={PHONE_CODES}
                      placeholder="Código"
                      value={field.state.value}
                      onChange={(e) => field.handleChange(e.value)}
                      disabled={patientQuery.isLoading}
                    />
                  )}
                </form.Field>

                <form.Field name="optionalPhoneNumber">
                  {(field) => (
                    <InputText
                      id="optionalPhoneNumber"
                      name="optionalPhoneNumber"
                      inputMode="numeric"
                      keyfilter="pint"
                      maxLength={7}
                      value={field.state.value}
                      onChange={(e) => field.handleChange(e.target.value)}
                      onPaste={(e) => {
                        const text = e.clipboardData.getData("text/plain");
                        const newText = text.replace(/\D/g, "");

                        if (newText.length === 11) {
                          const phoneCode = newText.slice(0, 4);
                          const result = PHONE_CODES.find((item) => {
                            return (
                              item.value === phoneCode ||
                              item.label === phoneCode
                            );
                          });

                          if (result) {
                            form.setFieldValue(
                              "optionalPhoneCode",
                              result.value
                            );
                            form.setFieldValue(
                              "optionalPhoneNumber",
                              newText.slice(4)
                            );
                          }
                          form.setFieldValue(
                            "optionalPhoneNumber",
                            newText.slice(4)
                          );
                        }
                        e.preventDefault();
                      }}
                      disabled={patientQuery.isLoading}
                    />
                  )}
                </form.Field>
              </div>
            </div>
          </div>
        </div>

        {/* BOTONES STEP */}

        <form.Subscribe
          selector={(state) => [
            state.canSubmit,
            state.isSubmitting,
            state.errorMap,
          ]}
          children={([canSubmit, isSubmitting, errors]) => (
            <div className="flex gap-2 justify-between mt-2">
              <Button
                type="button"
                icon="pi pi-save"
                label="Guardar"
                tooltip="Complete los datos"
                tooltipOptions={{ position: "top" }}
                className=""
                iconPos="right"
                disabled={!canSubmit}
                onClick={() => {
                  form.handleSubmit();
                }}
              />
            </div>
          )}
        />
      </form>
      {/* 
      {!!canSaveCase && (
        <Button
          type="button"
          icon="pi pi-save"
          label="Guardar caso"
          className="mt-4"
          severity="success"
          loading={newCaseMutation.isPending}
          disabled={newCaseMutation.isPending}
          onClick={() => newCaseMutation.mutate()}
        />
      )} */}
    </div>
  );
};

export default FormComponent;
