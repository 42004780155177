import React, { useRef, useState } from "react";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Paginator } from "primereact/paginator";
import { ProgressSpinner } from "primereact/progressspinner";
import { Menu } from "primereact/menu";

import {
  currencyFormat,
  formatCi,
  formatDateString,
  formatFullNumber,
  // getStatusChangeOptionsForRole,
  readableSex,
} from "../../utils";
import StatusTag from "../../components/StatusTag";
import { CaseStatus, GENDERS } from "../../constants";

import Dashboard from "../../Layout/Dashboard";
import {
  useRequirementTypes,
  useMedicalCasesSubscribe,
} from "../../hooks/queries";
import { useSuscribeCaseToPendingMutation } from "../../hooks/mutations";
import {
  ChangeStatusMultipleModal,
  ChangeStatusSingleModal,
} from "../../components/StatusChangeModal";
import { usePatchMedicalCase } from "../../hooks/mutations";
import { Toast } from "primereact/toast";
// import { StatusSelector } from "../components/StatusChangeSelector";
import { useMutation } from "@tanstack/react-query";
import { updateMultipleCasesStatus } from "../../services/medicalCases";
// import MedicalCenterModal from "../MedicalCenterModal";

/**
 * Dropdown to filter case patient sex
 */
const sexFilterTemplate = (options) => (
  <Dropdown
    value={options.value}
    options={GENDERS}
    onChange={(e) => options.filterApplyCallback(e.value)}
    placeholder="Seleccionar sexo"
    className="p-column-filter min-w-min"
    showClear
    style={{ maxWidth: "10rem" }}
  />
);

/**
 * Template to show the amount in currency format
 */
const amountBodyTemplate = (rowData) => {
  return currencyFormat(rowData.amount ?? 0);
};

/**
 * Status tag for filter selector
 * @param {string} option
 */
const statusItemTemplate = (option) => {
  return <StatusTag value={option} />;
};

/**
 * Status filter dropdown
 * @param {string[]} options
 */
const statusRowFilterTemplate = (options) => {
  return (
    <Dropdown
      value={options.value}
      options={Object.values(CaseStatus)}
      onChange={(e) => options.filterApplyCallback(e.value)}
      itemTemplate={statusItemTemplate}
      placeholder="Seleccionar status"
      className="p-column-filter min-w-min"
      showClear
    />
  );
};

/**
 * Medical cases table
 */
export default function MedicalCasesSubscribeList() {
  // Get medical cases
  const casesQuery = useMedicalCasesSubscribe();
  const [rowSubscribe, setRowSubscribe] = useState(null);
  const [showSubscribeDialog, setshowSubscribeDialog] = useState(false);
  const [messageSubscribeResponse, setMessageSubscribeResponse] = useState("");
  const [rowEdit, setRowEdit] = useState(null);
  const navigate = useNavigate();
  const menuEdit = useRef(null);

  const itemsEdit = [
    {
      label: "Acciones",
      items: [
        {
          label: "Ver detalles",
          icon: "pi pi-eye",
          command: () => {
            navigate("/casos/" + rowEdit?.id);
          },
        },
        {
          label: "Editar paciente",
          icon: "pi pi-id-card",
          command: () => {
            // setShowPersonDialog(true);
            console.log(rowEdit)
            navigate("/persona/editar/" + rowEdit?.patient_.id)
          }
            
          
        },
        {
          label: "Crear nuevo caso",
          icon: "pi pi-building-columns",
          command: () => {
            // setShowMedicalCenterDialog(true);
            navigate("/casos/nuevo/" + rowEdit?.id)
          },
        },
      ],
    },
  ];

  const suscribeCaseToPendingMutation = useSuscribeCaseToPendingMutation({
    onSuccess: ({ isOk, message }) => {
      setshowSubscribeDialog(true);
      casesQuery.refetch();
      if (message === "Asegurado No Existe") {
        setMessageSubscribeResponse("Paciente pendiente por suscribir");
        return;
      }
      setMessageSubscribeResponse(message);
    },
  });

  // Case detail link
  const ViewDetailBodyTemplate = (row) => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Link to={String(row.id)} className="p-button text-sm p-2">
        {/* <span className="p-button-icon p-button-icon-left pi pi-eye"></span> */}
        <span className="p-button-label">Ver</span>
      </Link>
      <Button
        label="Suscribir"
        severity="warning"
        className="p-button-success ml-2 p-2"
        onClick={() => {
          // Add your subscribe action here
          suscribeCaseToPendingMutation.mutate({ caseId: row.id });
          setshowSubscribeDialog(true);
          setRowSubscribe(row);
          console.log(`Subscribing to case ${row.id}`);
        }}
      />
      {/* <Button
        label="Editar"
        icon="pi pi-pencil"
        severity="secondary"
        className=" ml-2 p-2"
        onClick={() => {
          setShowMedicalCenterDialog(true);
          setRowEdit(row.medical_center_name);
        }}
      /> */}
      <Menu model={itemsEdit} popup ref={menuEdit} id="popup_menu_left" />
      <Button
        label="Acciones"
        severity="secondary"
        className="ml-2 p-2"
        onClick={(event) => {
          setRowEdit(row);
          menuEdit.current.toggle(event);
        }}
        aria-controls="popup_menu_left"
        aria-haspopup
      />
    </div>
  );

  // Status change
  const [rowChanging, setRowChanging] = useState(null);
  const [newStatus, setNewStatus] = useState(null);
  const [newStatusMultiple, setNewStatusMultiple] = useState(null);
  const [showMultipleModal, setShowMultipleModal] = useState(false);

  const toastRef = useRef(null);

  // Table multiple selection
  const [selectedRows, setSelectedRows] = useState(null);

  // Get requirement types
  const requirementTypesQuery = useRequirementTypes();

  // Update medical case from listing
  const caseMutation = usePatchMedicalCase({
    onSuccess: ({ type, message }) => {
      if (type === "success") {
        toastRef.current.show({
          severity: "success",
          summary: "Éxito",
          detail: message,
        });
        casesQuery.refetch();
        setRowChanging(null);
      } else {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: message,
        });
        setRowChanging(null);
      }
    },
  });

  // Update multiple cases status
  const caseMultipleMutation = useMutation({
    mutationFn: updateMultipleCasesStatus,
    onSuccess: ({ type, message }) => {
      if (type === "success") {
        toastRef.current.show({
          severity: "success",
          summary: "Éxito",
          detail: message,
        });
        casesQuery.refetch();
        setShowMultipleModal(false);
        setSelectedRows([]);
        setNewStatusMultiple(null);
      } else {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: message,
        });
        setShowMultipleModal(false);
      }
    },
  });

  // Global filters
  const [filters] = useState({
    // global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    created: { value: null, matchMode: FilterMatchMode.CONTAINS },
    id: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "patient_.sex": { value: null, matchMode: FilterMatchMode.EQUALS },
    requirement_type_names: { value: null, matchMode: FilterMatchMode.EQUALS },
    "holder_.ci": { value: null, matchMode: FilterMatchMode.CONTAINS },
    "patient_.ci": { value: null, matchMode: FilterMatchMode.CONTAINS },
    "patient_.name": { value: null, matchMode: FilterMatchMode.CONTAINS },
    requirement_names: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "patient_.birthdate": { value: null, matchMode: FilterMatchMode.CONTAINS },
    diagnosis_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    medical_center_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    subsidiary_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "patient_.phone": { value: null, matchMode: FilterMatchMode.CONTAINS },
    observation_rms: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  // const [globalFilterValue, setGlobalFilterValue] = useState("");

  // const onGlobalFilterChange = (e) => {
  //   const value = e.target.value;
  //   let _filters = { ...filters };

  //   _filters["global"].value = value;

  //   setFilters(_filters);
  //   setGlobalFilterValue(value);
  // };

  /**
   * Status tag for table body
   */
  const statusBodyTemplate = (rowData) => (
    <StatusTag value={rowData.status} />
    // <StatusSelector
    //   currentStatus={rowData.status}
    //   selectedStatus={rowData.status}
    //   onChange={(e) => {
    //     setNewStatus(e.value);
    //     setRowChanging(rowData);
    //   }}
    // />
  );

  /**
   * Dropdown to filter case requirement type
   */
  const requirementTypeFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      loading={requirementTypesQuery.isLoading}
      disabled={requirementTypesQuery.isLoading}
      options={requirementTypesQuery.data ?? []}
      optionValue="name"
      optionLabel="name"
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder="Seleccionar tipo"
      className="p-column-filter min-w-min"
      showClear
      filter
      style={{ maxWidth: "10rem" }}
    />
  );

  const exportExcel = () => {
    const data = casesQuery.data.map((item) => {
      return {
        Fecha: item.created,
        "Caso #": item.id,
        "CI Titular": item.holder_.ci,
        "CI Paciente": item.patient_.ci,
        "Nombre Paciente": item.patient_.name,
        Filial: item.subsidiary_name,
        "Teléfono Paciente": item.patient_.phone,
        "Fecha de Nacimiento": item.patient_.birthdate,
        Sexo: readableSex(item.patient_.sex),
        "Tipo de requerimiento": item.requirement_type_names,
        Requerimiento: item.requirement_names,
        "Monto (Bs.)": item.amount,
        Diagnóstico: item.diagnosis_name,
        Proveedor: item.medical_center_name,
        Status: item.status,
        Observaciones: item.observation_rms,
      };
    });
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "products");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const truncateText = (text, maxLength) => {
    if (!text) return "";
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  /**
   * Cases table header
   */
  const TableHeader = ({ newStatus, changeNewStatus, onStatusChangeClick }) => {
    return (
      <div className="full-w-header">
        <Button
          label="Exportar"
          icon="pi pi-file-excel"
          className="p-button-success"
          onClick={exportExcel}
        />
        {/* <div>
          <Dropdown
            placeholder="Cambiar status en lote"
            options={getStatusChangeOptionsForRole()}
            itemTemplate={statusItemTemplate}
            valueTemplate={
              newStatus !== null && (
                <span>
                  Cambiar a: <StatusTag value={newStatus} />
                </span>
              )
            }
            value={newStatus}
            onChange={(e) => changeNewStatus(e.value)}
          />
          <Button
            label="Aplicar"
            className="ml-3"
            onClick={onStatusChangeClick}
          />
        </div> */}
        {/* <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Buscar palabra clave"
          />
        </IconField> */}
      </div>
    );
  };

  return (
    <Dashboard headerTitle="Casos">
      {/* <MedicalCenterModal
        onHide={() => {
          setShowMedicalCenterDialog(!showMedicalCenterDialog);
        }}
        medicalCenter={rowEdit}
        visible={showMedicalCenterDialog}
      /> */}


      <div className="card">
        <Toast ref={toastRef} position="top-center" />
        <DataTable
          value={casesQuery.data ?? []}
          rows={10}
          dataKey="id"
          size={"small"}
          filters={filters}
          loading={casesQuery.isLoading}
          // globalFilterFields={[
          //   "name",
          //   "sex",
          //   "requirementType",
          //   "status",
          // ]}
          header={
            <TableHeader
              newStatus={newStatusMultiple}
              changeNewStatus={setNewStatusMultiple}
              onStatusChangeClick={() => setShowMultipleModal(true)}
            />
          }
          emptyMessage="No se hallaron casos."
          selectionMode="checkbox"
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          stripedRows
          scrollable
          removableSort
        >
          <Column selectionMode="multiple" headerStyle={{ width: "3rem" }} />
          <Column
            field="created"
            header="Fecha"
            filter
            filterPlaceholder="Buscar por fecha"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
          />
          <Column
            field="id"
            header="Caso #"
            sortable
            filter
            filterPlaceholder="Buscar por Nro. de Caso"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
          />
          <Column
            field="holder_.ci"
            header="CI Titular"
            filter
            filterPlaceholder="Buscar por Titular"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
            bodyClassName="readable-cell"
            body={(row) => formatCi(row.holder_.ci)}
          />
          <Column
            field="patient_.ci"
            header="CI Paciente"
            filter
            filterPlaceholder="Buscar por CI Paciente"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
            bodyClassName="readable-cell"
            body={(row) => formatCi(row.patient_.ci)}
          />
          <Column
            field="patient_.name"
            header="Nombre Paciente"
            filter
            filterPlaceholder="Buscar por Nombre"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
            bodyClassName="readable-cell"
          />
          <Column
            field="subsidiary_name"
            header="Filial"
            filter
            filterPlaceholder="Buscar por Filial"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
          />
          <Column
            field="patient_.phone"
            header="Teléfono Paciente"
            filter
            filterPlaceholder="Buscar por Teléfono"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
            bodyClassName="readable-cell"
            body={(row) => formatFullNumber(row.patient_.phone)}
          />
          <Column
            field="patient_.birthdate"
            header="Fecha de Nacimiento"
            filter
            filterPlaceholder="Buscar por Fecha"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
            body={(row) => formatDateString(row.patient_.birthdate)}
          />
          <Column
            field="patient_.sex"
            header="Sexo"
            filter
            filterElement={sexFilterTemplate}
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
            body={(row) => readableSex(row.patient_.sex)}
          />
          <Column
            field="requirement_type_names"
            header="Tipo de requerimiento"
            filter
            filterElement={requirementTypeFilterTemplate}
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
          />
          <Column
            field="requirement_names"
            header="Requerimiento"
            filter
            filterPlaceholder="Buscar por Requerimiento"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
            bodyClassName="readable-cell"
          />
          <Column
            field="amount"
            header="Monto (Bs.)"
            filter
            filterPlaceholder="Buscar por Monto"
            showFilterMenu={false}
            body={amountBodyTemplate}
            bodyClassName="readable-cell"
            sortable
          />
          <Column
            field="diagnosis_name"
            header="Diagnóstico"
            filter
            filterPlaceholder="Buscar por Diagnóstico"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
            bodyClassName="readable-cell"
          />
          <Column
            field="medical_center_name"
            header="Proveedor"
            filter
            filterPlaceholder="Buscar por Proveedor"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
            bodyClassName="readable-cell"
          />
          <Column
            field="observation_rms"
            header="Observaciones"
            filter
            filterPlaceholder="Buscar por Observaciones"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
            body={(row) => <p>{truncateText(row.observation_rms, 120)}</p>}
            bodyClassName="readable-cell"
          />
          <Column
            field="status"
            header="Status"
            showFilterMatchModes={false}
            showClearButton={false}
            showApplyButton={false}
            body={statusBodyTemplate}
            filter
            filterElement={statusRowFilterTemplate}
            frozen
            alignFrozen="right"
          />
          <Column
            header="Acción"
            body={ViewDetailBodyTemplate}
            frozen
            alignFrozen="right"
          />
        </DataTable>
        <Paginator rows={15} />
        <ChangeStatusSingleModal
          rowChanging={rowChanging}
          newStatus={newStatus}
          show={rowChanging !== null}
          onHide={() => {
            if (rowChanging === null) return;
            setRowChanging(null);
            setNewStatus(null);
          }}
          onSave={() => {
            if (rowChanging === null || newStatus === null) return;
            caseMutation.mutate({
              caseId: rowChanging.id,
              data: { status: newStatus },
            });
          }}
        />
        <ChangeStatusMultipleModal
          show={showMultipleModal}
          selection={selectedRows}
          newStatus={newStatusMultiple}
          onHide={() => {
            if (!showMultipleModal) return;
            setShowMultipleModal(false);
          }}
          onSave={() => {
            if (!showMultipleModal || !newStatusMultiple) return;
            caseMultipleMutation.mutate({
              caseIds: selectedRows.map((row) => row.id),
              status: newStatusMultiple,
            });
          }}
          loading={caseMultipleMutation.isPending}
        />
      </div>
      {/* Modal Subscribe */}
      <Dialog
        header="Suscripción"
        visible={showSubscribeDialog}
        style={{ maxWidth: "35rem" }}
        onHide={() => {
          if (showSubscribeDialog) setshowSubscribeDialog(false);
        }}
        draggable={false}
      >
        {suscribeCaseToPendingMutation.isPending && (
          <div className="p-fluid text-center">
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="8"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
            <p>Cargando...</p>
          </div>
        )}
        {!suscribeCaseToPendingMutation.isPending && (
          <div className="p-fluid text-center">
            <p>
              <strong>Caso #: </strong>
              {rowSubscribe?.id} <br />
              <strong> Titular: </strong> {formatCi(rowSubscribe?.holder_.ci)}{" "}
              <br />
              <strong>Paciente:</strong> {rowSubscribe?.patient_.name} <br />
              <strong>Cédula:</strong>
              {formatCi(rowSubscribe?.patient_.ci)} <br />
            </p>

            <p>{messageSubscribeResponse}</p>
          </div>
        )}
        <br></br>
        <div className="delete-confirm-buttons-container">
          <Button
            type="button"
            label="Aceptar"
            style={{ backgroundColor: "#999999", borderColor: "#999999" }}
            disabled={suscribeCaseToPendingMutation.isPending}
            onClick={() => {
              if (showSubscribeDialog) setshowSubscribeDialog(false);
            }}
          />
        </div>
      </Dialog>
    </Dashboard>
  );
}
