import { InputText } from "primereact/inputtext";
import { useState } from "react";

/**
 * InputMask adaptation for native-like date inputs
 * @param {object} props
 * @param {string} props.name
 * @param {string} props.value
 * @param {(val: string) => void} props.setValue
 * @param {boolean} props.disabled
 */
export default function VSieteDateInput({ name, value, setValue, disabled }) {
  const [isInvalid, setIsInvalid] = useState(false);

  // useEffect(() => {
  //   setIsInvalid(isInvalid);
  // },[value, isInvalid]);
  

  const onChange = (e) => {
    // let v = getKeyCode(e.target.value);
    console.log("Value",e.target.value);
    const lastChar = e.target.value.charAt(e.target.value.length - 1); // Obtiene el último carácter
    if (lastChar === "/"){
      console.log("Ultimo caracter es /");
      setValue(e.target.value)
      return;
    }
    let input = e.target.value.replace(/\D/g, "");
    let formatted = "";
    
    if (input.length >= 3) {
      formatted += input.slice(0, 2) + "/";
      input = input.slice(2);
    }
    if (input.length >= 3) {
      const month = input.slice(0, 2);
      if (parseInt(month, 10) > 12) {
        setIsInvalid(true) // Return invalid value
        return;
      } else {
        formatted += month + "/";
        input = input.slice(2);
      }
    }
    if (input.length > 0) {
      formatted += input.slice(0, 4);
    }
    console.log(formatted)
    setValue(formatted);
    setIsInvalid(e.target.value.length > 0 && e.target.value.length !== 10);
  };
  /**
   * @param {React.KeyboardEvent<HTMLInputElement>} e
   */
  // const handleKeyDown = (e) => {s
    // console.log(e);
    // var kCd = e.keyCode || e.which;
    // if (kCd == 0 || kCd == 229) {
    //   //for android chrome keycode fix
    //   kCd = getKeyCode(e.target.value);
    // }
    // console.log("Value", e.target.value);
    // console.log(kCd);
    // if (e.ctrlKey || e.shiftKey) {
    //   // Allow default copy/paste behavior
    //   return;
    // }
    // if (e.key === "v") {
    //   return;
    // }

    // let input = e.target.value.replace(/\D/g, "");
    // let formatted = "";

    // if (e.key >= '0' && e.key <= '9') {
    //   if (input.length === 0) {
    //     if (e.key > '3') {
    //       input += '0' + e.key;
    //     } else {
    //       input += e.key;
    //     }
    //   } else if (input.length === 2 && e.key > '1') {
    //     input += '0' + e.key;
    //   } else {
    //     input += e.key;
    //   }
    // } else if (e.key === 'Backspace') {
    //   input = input.slice(0, -1);
    // }
    // else {
    //   e.preventDefault();
    //   return;
    // }

    // if (input.length >= 2) {
    //   formatted += input.slice(0, 2) + "/";
    //   input = input.slice(2);
    // }
    // if (input.length >= 2) {
    //   const month = input.slice(0, 2);
    //   if (parseInt(month, 10) > 12) {
    //     setValue(e.target.value); // Return invalid value
    //     return;
    //   } else {
    //     formatted += month + "/";
    //     input = input.slice(2);
    //   }
    // }
    // if (input.length > 0) {
    //   formatted += input.slice(0, 4);
    // }

    // if (formatted.length === 10 && formatted.slice(-4).length !== 4) {
    //   formatted = formatted.slice(0, 6) + '19' + formatted.slice(-2);
    // }
    // setValue(e.target.value); // Set the value to the input
    // setValue(formatted);
    // setIsInvalid(formatted.length > 0 && formatted.length !== 10);
    // e.preventDefault();
  // };

  /**
   * @param {React.ClipboardEvent<HTMLInputElement>} e
   */
  const handlePaste = (e) => {
    let formatted = "";
    const pastedText = e.clipboardData.getData("text").replace(/\D/g, "");
    if (pastedText.length === 8) {
      const day = pastedText.slice(0, 2);
      const month = pastedText.slice(2, 4);
      const year = pastedText.slice(4, 8);
      if (
        parseInt(day, 10) <= 31 &&
        parseInt(month, 10) <= 12 &&
        year.length === 4
      ) {
        formatted = `${day}/${month}/${year}`;
      }
    }
    if (pastedText.length === 6) {
      const day = pastedText.slice(0, 2);
      const month = pastedText.slice(2, 4);
      const year = pastedText.slice(4, 6);
      if (
        parseInt(day, 10) <= 31 &&
        parseInt(month, 10) <= 12 &&
        year.length === 2
      ) {
        const year_now = new Date().getFullYear().toString().slice(2, 4);
        if (parseInt(year, 10) > parseInt(year_now, 10)) {
          formatted = `${day}/${month}/19${year}`;
        } else {
          formatted = `${day}/${month}/20${year}`;
        }
      }
    }
    setValue(formatted);
    setIsInvalid(formatted.length > 0 && formatted.length !== 10);
  };

  return (
    <div>
      <InputText
        name={name}
        placeholder="dd/mm/aaaa"
        value={value}
        onPaste={handlePaste}
        // onKeyDown={handleKeyDown}
        onChange={onChange}
        disabled={disabled}
        invalid={isInvalid}
      />
      {isInvalid && (
        <small className="p-error">Fecha inválida (dia/mes/año)</small>
      )}
    </div>
  );
}
