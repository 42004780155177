// Constantes

/**
 * API base URL
 */
export const BASE_URL = process.env.REACT_APP_API_URL;
// export const BASE_URL = "http://localhost:8002/api/v1/";

/**
 * Genders list for dropdowns
 */
export const GENDERS = [
  { label: "Masculino", value: "M" },
  { label: "Femenino", value: "F" },
];

export const CONDITIONS = [
  { label: "Activo", value: "A" },
  { label: "Jubilado", value: "J" },
  { label: "Sobreviviente", value: "S" },
];
/**
 * Phone codes for dropdowns
 */
export const PHONE_CODES = [
  { label: "0412", value: "58412" },
  { label: "0414", value: "58414" },
  { label: "0416", value: "58416" },
  { label: "0424", value: "58424" },
  { label: "0426", value: "58426" },
];

/**
 * Months list for dropdowns
 */
export const MONTHS = [
  { label: "Enero", value: 1 },
  { label: "Febrero", value: 2 },
  { label: "Marzo", value: 3 },
  { label: "Abril", value: 4 },
  { label: "Mayo", value: 5 },
  { label: "Junio", value: 6 },
  { label: "Julio", value: 7 },
  { label: "Agosto", value: 8 },
  { label: "Septiembre", value: 9 },
  { label: "Octubre", value: 10 },
  { label: "Noviembre", value: 11 },
  { label: "Diciembre", value: 12 },
];

/**
 * List of user roles for dropdowns
 */
export const USER_ROLES_DROPDOWN = [
  { label: "Conciliador", value: "conciliator" },
  { label: "Auditor", value: "auditor" },
  { label: "Gerente general", value: "manager" },
  { label: "Analista regional", value: "analyst" },
  { label: "Proveedor", value: "medicalCenter" },
];

/**
 * List of day numbers with labels
 */
export const DAYS = [
  { id: 1, shortLabel: "Lun.", fullLabel: "Lunes", apiLabel: "lunes" },
  { id: 2, shortLabel: "Mar.", fullLabel: "Martes", apiLabel: "martes" },
  { id: 3, shortLabel: "Mie.", fullLabel: "Miércoles", apiLabel: "miércoles" },
  { id: 4, shortLabel: "Jue.", fullLabel: "Jueves", apiLabel: "jueves" },
  { id: 5, shortLabel: "Vie.", fullLabel: "Viernes", apiLabel: "viernes" },
  { id: 6, shortLabel: "Sab.", fullLabel: "Sábado", apiLabel: "sábado" },
  { id: 7, shortLabel: "Dom.", fullLabel: "Domingo", apiLabel: "domingo" },
];

/**
 * List of possible medical case file types
 */
export const MEDICAL_FILE_TYPES = [
  { label: "Orden médica", value: "orden_medica" },
  { label: "Examen médico", value: "examen_medico" },
  { label: "Resultado de examen", value: "resultado_examen" },
  { label: "Receta médica", value: "receta_medica" },
  { label: "Estudio médico", value: "estudio_medico" },
  { label: "Cédula", value: "cedula" },
  { label: "Ficha del titular", value: "ficha_titular" },
  { label: "Informe médico", value: "informe_medico" },
  { label: "Imagenología", value: "imagenologia" },
  { label: "Otro", value: "otro" },
];

/**
 * Class of available medical case status
 */
export class CaseStatus {
  static PENDIENTE = "pendiente";
  static RESCHEDULED = "agendado";
  static REMESADO = "remesado";
  static ATENDIDO = "atendido";
  static CONCILIADO = "conciliado";
  static AUDITADO = "auditado";
  static ANULADO = "anulado";
  static TO_REVIEW = "en_revision";
  static TO_SUBSCRIBE = "por_suscribir";
  static WITHOUT_FUNDS = "sin_fondo";

  static colors = {
    [CaseStatus.TO_REVIEW]: "#a1a1a1",
    [CaseStatus.TO_SUBSCRIBE]: "#f0b100",
    [CaseStatus.ATENDIDO]: "#84cc16",
    [CaseStatus.REMESADO]: "#0ea5e9",
    [CaseStatus.RESCHEDULED]: "#14b8a6",
    [CaseStatus.PENDIENTE]: "#f97316",
    [CaseStatus.CONCILIADO]: "#7e22ce",
    [CaseStatus.AUDITADO]: "#22c55e",
    [CaseStatus.ANULADO]: "#ef4444",
    [CaseStatus.WITHOUT_FUNDS]: "#ff6467",
  };

  static getColor(status) {
    return CaseStatus.colors[status] || "transparent";
  }

  
  static labels = {
    [CaseStatus.PENDIENTE]: "Pendiente",
    [CaseStatus.RESCHEDULED]: "Agendado",
    [CaseStatus.REMESADO]: "Remesado",
    [CaseStatus.ATENDIDO]: "Atendido",
    [CaseStatus.CONCILIADO]: "Conciliado",
    [CaseStatus.AUDITADO]: "Auditado",
    [CaseStatus.ANULADO]: "Anulado",
    [CaseStatus.TO_REVIEW]: "Revisión",
    [CaseStatus.TO_SUBSCRIBE]: "Por Suscribir",
    [CaseStatus.WITHOUT_FUNDS]: "Sin Fondo",
  }
  static getLabel(status) {
    return CaseStatus.labels[status] || "Desconocido";
  }
}

/**
 * Class of available user roles
 */
export class UserRole {
  static MASTER = "master";
  static CONCILIATOR = "conciliator";
  static AUDITOR = "auditor";
  static MEDICAL_CENTER = "medical_center";
  static ANALYST = "analyst";
  static MANAGER = "manager";
  static ANALYST_SUPPLIER = "analyst_supplier";
  static ANALYST_COMPANY_CLIENT = "analyst_company_client";
  static CALL_CENTER = "call_center";
  static LABORATORY = "laboratory";

  static all() {
    return [
      UserRole.MASTER,
      UserRole.CONCILIATOR,
      UserRole.AUDITOR,
      UserRole.MEDICAL_CENTER,
      UserRole.ANALYST,
      UserRole.MANAGER,
      UserRole.ANALYST_SUPPLIER,
      UserRole.ANALYST_COMPANY_CLIENT,
      UserRole.CALL_CENTER,
      UserRole.LABORATORY
    ];
  }
}

const ROLE_TO_ROUTE = {
  [UserRole.MASTER]: "/casos",
  [UserRole.CONCILIATOR]: "/casos",
  [UserRole.AUDITOR]: "/auditorias",
  [UserRole.MEDICAL_CENTER]: "/casos",
  [UserRole.ANALYST]: "/casos",
  [UserRole.MANAGER]: "/casos",
  [UserRole.ANALYST_SUPPLIER]: "/proveedores",
  [UserRole.ANALYST_COMPANY_CLIENT]: "/casos",
  [UserRole.CALL_CENTER]: "/casos",
  [UserRole.LABORATORY]: "/casos",
};

export const getRouteForRole = (role) => {
  return ROLE_TO_ROUTE[role] || "/"; // Ruta por defecto si el rol no está definido
};

/**
 * List of roles with allowed access per route
 */
export const ROUTE_ALLOWED_ROLES = {
  // Casos Médicos
  "/casos/nuevo": [
    UserRole.CONCILIATOR,
    UserRole.ANALYST,
    UserRole.MANAGER,
    UserRole.ANALYST_COMPANY_CLIENT,
    UserRole.CALL_CENTER,
    UserRole.LABORATORY,
    UserRole.MASTER,
  ],
  "/casos": [
    UserRole.CONCILIATOR,
    UserRole.MEDICAL_CENTER,
    UserRole.ANALYST,
    UserRole.MANAGER,
    UserRole.ANALYST_COMPANY_CLIENT,
    UserRole.CALL_CENTER,
    UserRole.ANALYST_SUPPLIER,
    UserRole.LABORATORY,
    UserRole.MASTER
  ],
  "/casos/por-suscribir":[
    UserRole.MANAGER,
    UserRole.CALL_CENTER,
    UserRole.MASTER
  ],
  "/casos/por-suscribir/:caseId":[
    UserRole.MANAGER,
    UserRole.CALL_CENTER,
    UserRole.MASTER
  ],
  "/casos/:caseId": [
    UserRole.CONCILIATOR,
    UserRole.MEDICAL_CENTER,
    UserRole.ANALYST,
    UserRole.MANAGER,
    UserRole.ANALYST_COMPANY_CLIENT,
    UserRole.LABORATORY,
    UserRole.CALL_CENTER,
    UserRole.ANALYST_SUPPLIER,
    UserRole.MASTER
  ],
  "/requerimientos": [UserRole.CONCILIATOR, UserRole.MASTER],

  // Anticipos
  "/anticipos/nuevo": [UserRole.CONCILIATOR, UserRole.AUDITOR, UserRole.MASTER],
  "/anticipos": [
    UserRole.CONCILIATOR,
    UserRole.AUDITOR,
    UserRole.CALL_CENTER,
    // UserRole.MEDICAL_CENTER,
    UserRole.MASTER

  ],
  "/anticipos/proveedores/:centerId": [
    // UserRole.MEDICAL_CENTER,
    UserRole.CONCILIATOR,
    UserRole.AUDITOR,
    UserRole.CALL_CENTER,
    UserRole.MASTER
  ],

  // Baremos
  "/baremos": [
    UserRole.CONCILIATOR,
    UserRole.MANAGER,
    UserRole.AUDITOR,
    UserRole.ANALYST_SUPPLIER,
    UserRole.MASTER
  ],
  "/baremos/nuevo": [
    UserRole.CONCILIATOR,
    UserRole.MANAGER,
    UserRole.AUDITOR,
    UserRole.ANALYST_SUPPLIER,
    UserRole.MASTER
  ],
  "/baremos/:baremoId": [
    UserRole.CONCILIATOR,
    UserRole.MANAGER,
    UserRole.AUDITOR,
    UserRole.ANALYST_SUPPLIER,
    UserRole.MASTER
  ],

  // Proveedores
  "/proveedores": [
    UserRole.CONCILIATOR,
    // UserRole.MEDICAL_CENTER,
    UserRole.MANAGER,
    UserRole.AUDITOR,
    UserRole.ANALYST_SUPPLIER,
    UserRole.MASTER
  ],
  "/proveedores/nuevo": [
    UserRole.CONCILIATOR,
    // UserRole.MEDICAL_CENTER,
    UserRole.MANAGER,
    UserRole.AUDITOR,
    UserRole.ANALYST_SUPPLIER,
    UserRole.MASTER
  ],
  "/centro-medico/servicios": [
    UserRole.CONCILIATOR,
    // UserRole.MEDICAL_CENTER,
    UserRole.MANAGER,
    UserRole.AUDITOR,
    UserRole.ANALYST_SUPPLIER,
    UserRole.MASTER
  ],
  "/proveedores/editar/:centerId": [
    UserRole.CONCILIATOR,
    // UserRole.MEDICAL_CENTER,
    UserRole.MANAGER,
    UserRole.AUDITOR,
    UserRole.ANALYST_SUPPLIER,
    UserRole.MASTER
  ],

  // Facturas
  "/centro-medico/facturas": [],
  "/centro-medico/facturas/nueva": [],

  // Usuarios
  "/usuarios": [],
  "/usuarios/cliente": [],

  "/reportes/casos-medicos": [],
  
  // Logout
  "/logout": UserRole.all(),
};
