import { BASE_URL } from "../constants";
import { fetchData } from "../utils";


/**
 * Returns list of medical cases, filtered by medicalCenterId
 */
export async function getReportMedicalCases(
  page,
  pageSize = 50,
  filters,
  allResults = false
) {
  const queryParams = new URLSearchParams();
  if (page) queryParams.append("page", page);
  queryParams.append("page_size", pageSize);
  if (filters) {
    Object.entries(filters).forEach(([key, value]) => {
      if (value) queryParams.append(key, value);
    });
  }
  if (allResults) {
    queryParams.delete("page");
    queryParams.delete("page_size");
    queryParams.append("page", 1);
    queryParams.append("page_size", 100);
    const data = await fetchData(
      `${BASE_URL}insurance/report-medical-case/?${queryParams.toString()}`
    );

    let results = data.results;

    // Get all data from pagination

    let nextUrl = data.next;
    while (nextUrl) {

      // If nextUrl begins with http, replace with https
      if (nextUrl.startsWith("http://")) {
        nextUrl = nextUrl.replace("http://", "https://");
      }
      try {
        const nextResponse = await fetchData(nextUrl);
        // const nextData = await nextResponse.json();
        results = results.concat(nextResponse.results);
  
        nextUrl = nextResponse.next;
      } catch (error) {
        console.log(error) 
        break;
      }
    }
    console.log("Data Results",results)
    return { results:results, count: results.length };
  }
  return fetchData(
    `${BASE_URL}insurance/report-medical-case/?${queryParams.toString()}`
  );
}
