// Services for people (holders and patients)

import { BASE_URL } from "../constants";
import { getAuthHeader } from "../utils";

/**
 * @typedef Person
 * @property {number} id
 * @property {string} ci
 * @property {string} name
 * @property {"M" | "F"} sex
 * @property {string} birthdate
 * @property {string} phone
 * @property {string} subsidiary
 * @property {boolean} is_holder
 * @property {number} insurance
 * @property {number | null} holder
 * @property {Person} holder_
 */


/**
 * Create a person
 */
export async function addPerson(data) {
  try {
    // Add person
    const response = await fetch(BASE_URL + "insurance/person/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeader(),
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      const json = await response.json();
      throw new Error(JSON.stringify(json));
    }
    const person = await response.json();
    return { type: "success", message: "Persona agregada correctamente.", personId: person.id };
  } catch (error) {
    console.error("Error adding person: ", error);
    return { type: "error", message: "Error registrando persona" };
  }
}

/**
 * Gets list of people matching CI from left to right
 * @param {string | undefined} ci 
 */
export async function getPeopleMatchingCI(ci) {
  try {
    let query = ci;
    if (!query) {
      query = "";
    }
    const response = await fetch(BASE_URL + "insurance/person/?ci__istartswith=" + query, {
      headers: {
        ...getAuthHeader(),
      },
    });
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    const data = await response.json();
    /**@type Person[] */
    const results = data.results;
    return results ?? [];
  } catch (error) {
    console.error("Error fetching person: ", error);
    return [];
  }
}

/**
 * Gets list of holders matching CI from left to right
 * @param {string | undefined} ci 
 */
export async function getHoldersMatchingCI(ci) {
  const people = await getPeopleMatchingCI(ci);
  return people.filter((person) => person.is_holder);
}

/**
 * Edit a person
 * @param {number} id
 * @param {Partial<Person>} data
 */
export async function updatePerson({id, data}) {
  try {
    const phone = data.phoneCode + data.phoneNumber;
    const optionalPhone =  data.optionalPhoneCode + data.optionalPhoneNumber;
    let ciPatient = data.ciType + data.ci;
    if (data.ciType === "M") {
      data.isMinor = true;
      ciPatient = data.holderCiType + data.holderCi;
    }
    const Data = {
      ci: ciPatient,
      name: data.name,
      sex: data.sex,
      birthdate: data.birthDate.split("/").reverse().join("-"),
      phone: phone,
      optional_phone: optionalPhone,
      is_minor: data.isMinor,
      minor_id: data.minorId,
    };


    const response = await fetch(`${BASE_URL}insurance/person/${id}/`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeader(),
      },
      body: JSON.stringify(Data),
    });
    if (!response.ok) {
      const json = await response.json();
      if(json.message){
        return { type: "error", message: json.message };
      }
      throw new Error(JSON.stringify(json));
    }
    const person = await response.json();
    return { type: "success", message: "Persona editada correctamente.", personId: person.id };
  } catch (error) {
    console.error("Error editing person: ", error);
    return { type: "error", message: "Error editando persona" };
  }
}
/**
 * Get a person by ID
 * @param {number} id
 * @returns {Promise<Person | null>}
 */
export async function getPerson(id) {
  try {
    const response = await fetch(`${BASE_URL}insurance/person/${id}/`, {
      headers: {
        ...getAuthHeader(),
      },
    });
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    const person = await response.json();
    return person;
  } catch (error) {
    console.error("Error fetching person: ", error);
    return null;
  }
}
