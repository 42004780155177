import { Tag } from "primereact/tag";
import { CaseStatus } from "../constants";

/**
 * Devuelve el color de fondo de la etiqueta de status de caso
 * @param {string} status
 */
// const getStatusBackground = (status) => {
//   switch (status) {
//     case "en_revision":
//       return "#a1a1a1";
//     case "por_suscribir":
//       return "#f0b100";
//     case "atendido":
//       return "#84cc16";
//     case "remesado":
//       return "#0ea5e9";
//     case "agendado":
//       return "#14b8a6";
//     case "pendiente":
//       return "#f97316";
//     case "conciliado":
//       return "#7e22ce";
//     case "auditado":
//       return "#22c55e";
//     case "anulado":
//       return "#fb2c36";
//     default:
//       return "transparent";
//   }
// };

/**
 * Etiqueta que muestra el status de un caso médico
 * @param {object} props
 * @param {string} props.value
 */
export default function StatusTag({ value, className }) {
  return (
    <Tag
      className={className}
      value={CaseStatus.getLabel(value)}
      style={{ backgroundColor: CaseStatus.getColor(value) }}
    />
  );
}
