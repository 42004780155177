import {
  useRef,
  useState,
  // useEffect
} from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import FormSubHeading from "../components/FormSubHeading";
import "./CreateCase.css";
import { MultiSelect } from "primereact/multiselect";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DeleteButton } from "../components/TableButtons";
import { GENDERS, PHONE_CODES, CONDITIONS } from "../constants";
import { addCase } from "../services/medicalCases";
import { getHoldersMatchingCI, getPeopleMatchingCI } from "../services/people";
import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";
import InputWithSuggestions from "../components/InputWithSuggestions";
import RequirementModal from "./RequirementModal";
import { useDiagnosisList, useSubsidiaries } from "../hooks/queries";
import { Divider } from "primereact/divider";
import VSieteDateInput from "../components/VSieteDateInput";
import { Dialog } from "primereact/dialog";
import {
  // getDependency,
  getHoldersRMSCI,
} from "../services/rms";
import { Card } from "primereact/card";
import { useForm } from "@tanstack/react-form";
import { useStore } from "@tanstack/react-form";
import { DataView } from "primereact/dataview";
import { classNames } from "primereact/utils";

/**
 * @typedef API_Person Structure of the Person object returned by the API
 * @property {number} id
 * @property {string} ci
 * @property {string} name
 * @property {"M" | "F"} sex
 * @property {string} birthdate
 * @property {string} phone
 * @property {string} subsidiary
 * @property {boolean} is_holder
 * @property {string | null} holder
 */

/**
 * @typedef TableRequirement Structure of each requirement shown on the table
 * @property {number} requirementType
 * @property {string} requirementTypeLabel
 * @property {number} requirement
 * @property {string} requirementLabel
 * @property {number} medicalCenter
 * @property {string} medicalCenterLabel
 */

function RequirementDataView({
  requirements,
  setRequirementList,
  setCenterSelected,
}) {
  function deleteRequirement(requirement) {
    const newList = requirements.filter((req) => req !== requirement);
    setRequirementList(newList);
    if (newList.length === 0) {
      setCenterSelected(null);
    }
  }
  const itemTemplate = (product, index) => {
    return (
      <div className="col-12" key={product.requirement}>
        <div
          className={classNames(
            "flex flex-column xl:flex-row xl:align-items-start p-3 gap-2",
            { "border-top-1 surface-border": index !== 0 }
          )}
        >
          <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
            <div className="flex flex-column align-items-center sm:align-items-start gap-1">
              <div className="text-lg font-bold text-900">
                {product.requirementLabel}
              </div>
              <div className="text-900">{product.requirementTypeLabel}</div>

              {/* <Rating value={product.rating} readOnly cancel={false}></Rating> */}
              <div className="flex align-items-center gap-3">
                <span className="flex align-items-center gap-2">
                  <i className="pi pi-building-columns"></i>
                  <span className="text-sm font-semibold">
                    {product.medicalCenterLabel}
                  </span>
                </span>
                {/* <Tag
                  value={product.inventoryStatus}
                  severity={getSeverity(product)}
                ></Tag> */}
              </div>
            </div>
            <div className="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2 mr-4 sm:mr-0">
              <DeleteButton onClick={() => deleteRequirement(product)} />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const listTemplate = (items) => {
    if (!items || items.length === 0) return null;

    let list = items.map((product, index) => {
      return itemTemplate(product, index);
    });

    return <div className="grid grid-nogutter">{list}</div>;
  };
  return <DataView value={requirements} listTemplate={listTemplate} />;
}

function FieldInfo({ field }) {
  return (
    <>
      {field.state.meta.isTouched && field.state.meta.errors.length ? (
        <small className="p-error">{field.state.meta.errors.join(",")}</small>
      ) : null}
      {/* {field.state.meta.isValidating ? 'Validating...' : null} */}
    </>
  );
}

const INITIAL_PATIENT_FORM = {
  ciType: "V",
  ci: "",
  name: "",
  sex: null,
  birthDate: "",
  phoneCode: "58412",
  phoneNumber: "",
  diagnosis: null,
  client: null,
  subsidiary: null,
  isHolder: true,
  shouldCreatePatient: false,
  minorId: "",
  isMinor: false,
};

const INITIAL_HOLDER_FORM = {
  step: 1,
  ciType: "V",
  ci: "",
  name: "",
  sex: null,
  birthDate: "",
  birthDay: null,
  birthMonth: null,
  birthYear: null,
  phoneCode: "58412",
  phoneNumber: "",
  shouldCreateHolder: false,
  condition: "A",
  optionalPhoneCode: "58412",
  optionalPhoneNumber: "",
  patient: INITIAL_PATIENT_FORM,
};

/**
 * Person search item template
 * @param {(item: API_Person) => void} onClick
 */
function createSearchItemTemplate(onClick) {
  /** @param {API_Person} item */
  return (item) => (
    <>
      <span className="py-1" onClick={() => onClick(item)}>
        {item.ci} - {item.name}
      </span>
      <Divider />
    </>
  );
}

/**
 * Formulario para crear un nuevo caso médico
 */
const FormComponent = () => {
  const form = useForm({
    initialValues: INITIAL_HOLDER_FORM,
    defaultValues: INITIAL_HOLDER_FORM,
    validators: {
      onMount: ({ value }) => {
        return {
          fields: {
            ci: value.ci ? undefined : "Cédula es requerida",
          },
        };
      },
      onChange: ({ value }) => {
        if (value.step === 1) {
          return {
            fields: {
              sex: value.sex ? undefined : "Seleccione un sexo",
              name: value.name ? undefined : "Nombre completo es requerido",
              birthDate:
                value.birthDate.length === 10
                  ? undefined
                  : "Fecha de nacimiento es requerida",
              condition: value.condition ? undefined : "Condicion es requerida",
              phoneNumber: value.phoneNumber ? undefined : "Número de teléfono es requerido",
              "patient.ci": undefined,
              "patient.name": undefined,
              "patient.sex": undefined,
              "patient.birthDate": undefined,
            },
          };
        }
        if (!value.patient.isHolder && value.step === 2) {
          return {
            fields: {
              "patient.ci": value.patient.ci
                ? undefined
                : "Cédula es requerida",
              "patient.sex": value.patient.sex
                ? undefined
                : "Seleccione un sexo",
              "patient.name": value.patient.name
                ? undefined
                : "Nombre completo es requerido",
              "patient.birthDate":
                value.patient.birthDate.length === 10
                  ? undefined
                  : "Fecha de nacimiento es requerida",
            },
          };
        }
        if (value.patient.isHolder && value.step === 2) {
          return {
            fields: {
              "patient.ci": undefined,
              "patient.name": undefined,
              "patient.sex": undefined,
              "patient.birthDate": undefined,
            },
          };
        }
        return {
          fields: {
            sex: value.sex ? undefined : "Seleccione un sexo",
            name: value.name ? undefined : "Nombre completo es requerido",
            birthDate:
              value.birthDate.length === 10
                ? undefined
                : "Fecha de nacimiento es requerida",
            condition: value.condition ? undefined : "Condicion es requerida",
          },
        };
      },
    },
    onSubmit: async ({ value }) => {
      // Do something with form data
      const { patient, ...holder } = value;

      newCaseMutation.mutate({
        patient: patient,
        holder: holder,
      });
    },
  });

  // Form state
  const [patientForm, setPatientForm] = useState(INITIAL_PATIENT_FORM);
  const [holderForm, setHolderForm] = useState(INITIAL_HOLDER_FORM);
  const [inputsLocked, setInputsLocked] = useState("");

  // const [holderLocked, setHolderLocked] = useState(false);
  // const [patientLocked, setPatientLocked] = useState(false);

  // Requirements
  const [showRequirementModal, setShowRequirementModal] = useState(false);
  const [requirementList, setRequirementList] = useState([]);
  const [editValues, setEditValues] = useState(null);
  const [selectedMedicalCenter, setSelectedMedicalCenter] = useState(null);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [duplicateCase, setDuplicateCase] = useState(false);
  // const [patientSelected, setPatientSelected] = useState(false);
  // const [holderList, setHolderList] = useState({data:[]});
  const dependency = [
    { name: "1", code: "1" },
    { name: "2", code: "2" },
    { name: "3", code: "3" },
    { name: "4", code: "4" },
    { name: "5", code: "5" },
  ]
  // const [dependency, setDependency] = useState([
  //   { name: "1", code: "1" },
  //   { name: "2", code: "2" },
  //   { name: "3", code: "3" },
  //   { name: "4", code: "4" },
  //   { name: "5", code: "5" },
  // ]);
  // console.log( patientSelected);
  // Get diagnosis list
  const diagnosisQuery = useDiagnosisList();

  // Get subsidiaries
  const subsidiariesQuery = useSubsidiaries();

  // Get holder by CI
  //   const holderQuery = useQuery({
  //   queryKey: ["holder", holderForm.ciType + holderForm.ci],
  //   queryFn: () => getHoldersMatchingCI(holderForm.ciType + holderForm.ci),
  //   enabled: holderForm.ci.length >= 4,
  // });
  const formStep = useStore(form.store, (state) => state.values.step);
  const holderCi = useStore(form.store, (state) => state.values.ci);
  const holderCiType = useStore(form.store, (state) => state.values.ciType);
  const holderName = useStore(form.store, (state) => state.values.name);
  const patientCiType = useStore(
    form.store,
    (state) => state.values.patient.ciType
  );

  const patientClient = useStore(
    form.store,
    (state) => state.values.patient.client
  );
  const patientIsHolder = useStore(
    form.store,
    (state) => state.values.patient.isHolder
  );
  // Get holder by CI in rms
  const holderRMSQuery = useQuery({
    queryKey: ["holder-rms", holderCiType + holderCi],
    queryFn: () => getHoldersRMSCI(holderCiType, holderCi),
    // enabled: holderForm.ci.length >= 4,
    enabled: false,
  });

  const holderQuery = useQuery({
    queryKey: ["holder", holderCiType + holderCi],
    queryFn: () => getHoldersMatchingCI(holderCiType + holderCi),
    // enabled: holderCi?.length >= 4,
    enabled: false,
  });
  const [holderResult, setHolderResult] = useState([]);
  const [patientResult, setPatientResult] = useState([]);
  // Get patient by CI
  const patientQuery = useQuery({
    queryKey: ["patient", patientForm.ciType + patientForm.ci],
    queryFn: () => getPeopleMatchingCI(patientForm.ciType + patientForm.ci),
    enabled: patientForm.ci.length >= 4,
  });

  const queryClient = useQueryClient();

  // Mutation for new case
  const newCaseMutation = useMutation({
    mutationFn: ({ patient, holder }) =>
      addCase({
        patient: patient,
        holder: holder,
        requirements: requirementList,
        duplicate: duplicateCase,
      }),
    onSuccess: ({ type, message, code, data }) => {
      if (type === "error") {
        if (Array.isArray(code) && code.includes("case_exists")) {
          console.log("caseExisr");
          handleDuplicateCase();
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
          });
        }
      } else {
        queryClient.invalidateQueries({
          queryKey: ["medicalCases"],
        });
        toast.current.show({
          severity: "success",
          summary: "Éxito",
          detail: "Caso médico registrado",
        });
        form.reset();
        form.onChange();
        // setPatientForm(INITIAL_PATIENT_FORM);
        // setHolderForm(INITIAL_HOLDER_FORM);
        setRequirementList([]);
        // setInputsLocked("");

        setSelectedMedicalCenter(null);
        holderSearchRef.current.hide();
        patientSearchRef.current.hide();
        setDuplicateCase(false);
      }
    },
  });

  // const getDependencyMutation = useMutation({
  //     mutationFn: getDependency,
  //     onSuccess: (data) => {

  //       if (!data.results) {
  //         setDependency([]);
  //         setHolderList({data: []});
  //         return
  //       }
  //       const dependency = data.results.filter((dep)=> dep.correl_aseg !==0).map((dep) => {
  //         return {name: dep.nombre, code: dep.correl_aseg}
  //       });
  //       const holder = data.results.filter((dep)=> dep.correl_aseg === 0);
  //       setDependency(dependency);
  //       setHolderList({data: holder});
  //     },
  // });

  // Fetch dependency data on component mount
  // useEffect(() => {
  //   console.log(patientForm.ciType, "patientForm.ciType");
  //   if(holderForm.ci.length >= 5){
  //     getDependencyMutation.mutate({dniCode: holderForm.ciType, dniNumber: holderForm.ci});
  //   }
  // }, [holderForm.ciType, holderForm.ci]);
  // Validate fields being set so requirement can be added
  const canAddRequirement =
    (patientForm.isHolder && holderForm.ci) ||
    Object.keys(patientForm).every((key) => {
      return patientForm[key] !== null && patientForm !== "";
    });

  // Validate fields being set so case can be saved
  const canSaveCase = canAddRequirement && requirementList.length > 0;

  // Form message toast
  const toast = useRef(null);

  // Patient and holder name inputs
  const holderNameRef = useRef(null);
  // const patientNameRef = useRef(null);

  // Search overlays
  const holderSearchRef = useRef(null);
  const patientSearchRef = useRef(null);

  /**
   * Key up event for holder search
   * @param {KeyboardEvent} e
   */
  const onHolderSearchEnter = (e) => {
    if (e.key === "Escape") {
      holderSearchRef.current.hide();
    }
    if (e.key === "Enter") {
      // const ci = holderForm.ciType + holderForm.ci;
      // if (
      //   !holderQuery.data ||
      //   !holderQuery.data.length ||
      //   ci !== holderQuery.data[0].ci
      // ) {
      //   setHolderForm({ ...holderForm, shouldCreateHolder: true });
      //   holderSearchRef.current.hide();
      //   // Wait 50ms for holderNameRef to be set
      //   setTimeout(() => {
      //     holderNameRef.current.focus();
      //   }, 50);
      //   return;
      // }
      // selectHolder(holderQuery.data[0]);
    }
  };

  /**
   * Key up event for patient search
   * @param {KeyboardEvent} e
   */
  // const onPatientSearchEnter = (e) => {
  //   if (patientSelected) return;

  //   if (e.key === "Escape") {
  //     patientSearchRef.current.hide();
  //   }
  //   if (e.key === "Enter") {
  //     if (!patientQuery.data || !patientQuery.data.length) {
  //       setPatientForm({ ...patientForm, shouldCreatePatient: true });
  //       patientSearchRef.current.hide();
  //       patientNameRef.current.focus();
  //     } else {
  //       selectPatient(patientQuery.data[0]);
  //     }
  //   }
  // };

  /**
   * Sets form state from a selected patient
   * @param {API_Person} holder
   */
  const selectHolder = (holder) => {
    console.log(holder);
    setHolderForm({
      ciType: holderForm.ciType,
      ci: holderForm.ci,
      // sex: holder.sex,
      // birthDate: holder.birthdate.split("-").reverse().join("/"),
      name: holder.nombre,
      // phoneCode: holder.phone.slice(0, 5),
      // phoneNumber: holder.phone.slice(5),
      shouldCreateHolder: false,
    });
    setPatientForm({ ...patientForm, isHolder: true });
    // setHolderLocked(true);
    holderSearchRef.current.hide();
  };

  /**
   * Sets form state from a selected patient
   * @param {API_Person} patient
   */
  // const selectPatient = (patient) => {
  //   console.log(patient, "selectPatient");
  //   if(patient.ciType === "M"){
  //     setPatientForm({
  //       ...patientForm,
  //       name: patient.name,
  //     });
  //   }
  //   setPatientForm({
  //     ...patientForm,
  //     ciType: patient.ci.slice(0, 1),
  //     ci: patient.ci.slice(1),
  //     name: patient.name,
  //     sex: patient.sex,
  //     birthDate: patient.birthdate.split("-").reverse().join("/"),
  //     phoneCode: patient.phone.slice(0, 5),
  //     phoneNumber: patient.phone.slice(5),
  //     subsidiary: patient.subsidiary,
  //     diagnosis: null,
  //     isHolder: false,
  //     shouldCreatePatient: false,
  //   });
  //   // setPatientLocked(true);
  //   setPatientSelected(true);
  //   patientSearchRef.current.hide();
  // };

  const handleDuplicateCase = () => {
    setShowDuplicateModal(true);
  };

  const confirmDuplicateCase = () => {
    setShowDuplicateModal(false);
    setDuplicateCase(true);
    newCaseMutation.mutate();
  };

  const duplicateModalFooter = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setShowDuplicateModal(false)}
        className="p-button-text"
      />
      <Button
        label="Si"
        icon="pi pi-check"
        onClick={confirmDuplicateCase}
        autoFocus
      />
    </div>
  );

  const duplicateModalContent = (
    <div>
      <p>¿Está seguro de que desea duplicar este caso?</p>
      {patientForm.isHolder ? (
        <p>
          <strong>Paciente:</strong> {holderForm.name}
        </p>
      ) : (
        <p>
          <strong>Paciente:</strong> {patientForm.name}
        </p>
      )}
      <ul>
        {requirementList.map((req, index) => (
          <li key={index}>
            {req.requirementTypeLabel} - {req.requirementLabel}
          </li>
        ))}
      </ul>
    </div>
  );

  const search = async (e) => {
    if (e.query && /\D/.test(e.query)) {
      form.setFieldValue("ci", e.query.replace(/\D/g, ""));
      e.query = e.query.replace(/\D/g, "");
      search(e);
      return;
    }
    const response = await getHoldersMatchingCI(holderCiType + e.query);
    // const response = await getHoldersRMSCI(holderCiType, e.query);
    // const changeHolder = response.map((item) => ({
    //   ...item,
    //   label: `${item.cedrif_aseg} - ${item.nombre}`,
    //   value: item.cedrif_aseg,
    // }));
    const changeHolder = response.map((item) => ({
      ...item,
      label: `${item.ci} - ${item.name}`,
      value: item.ci.replace(/\D/g, ""),
    }));
    setHolderResult(changeHolder);
    console.log(inputsLocked);
  };

  const searchPatient = async (e) => {
    if (e.query && /\D/.test(e.query)) {
      form.setFieldValue("patient.ci", e.query.replace(/\D/g, ""));
      e.query = e.query.replace(/\D/g, "");
      searchPatient(e);
      return;
    }
    const response = await getPeopleMatchingCI(patientCiType + e.query);
    const changeHolder = response.map((item) => ({
      ...item,
      label: `${item.ci} - ${item.name}`,
      value: item.ci.replace(/\D/g, ""),
    }));
    setPatientResult(changeHolder);
  };

  return (
    <div className="p-fluid">
      <Toast ref={toast} position="top-center" />
      <form>
        {(formStep === 2 || formStep === 3) && (
          <Card title="" className="mb-2 card-step">
            <FormSubHeading title="Titular" />
            <div className="ml-2 mr-2">
              <span>
                {" "}
                {holderCiType}-{holderCi} {holderName}{" "}
              </span>
            </div>
          </Card>
        )}
        {/* DATOS DEL TITULAR */}
        {formStep === 1 && (
          <div>
            <FormSubHeading title="Datos del Titular" />

            <div className="p-field">
              <label htmlFor="holderCi">Cédula</label>
              <div className="field-with-select">
                <form.Field name="ciType">
                  {(field) => (
                    <Dropdown
                      id={"holder" + field.name}
                      name={"holder" + field.name}
                      options={["V", "E"]}
                      placeholder="Tipo"
                      value={field.state.value}
                      onChange={(e) => field.handleChange(e.value)}
                      required
                    />
                  )}
                </form.Field>

                <form.Field name="ci">
                  {(field) => (
                    <InputWithSuggestions
                      inputName={field.name}
                      inputValue={field.state.value}
                      keyfilter="pint"
                      maxLength={9}
                      onCompleteMethod={search}
                      suggestions={holderResult}
                      onTextChange={(e) => {
                        if (e.value && e.value.hasOwnProperty("value")) {
                          field.handleChange(e.value.value);
                          // form.setFieldValue("name", e.value.nombre);
                          form.setFieldValue("name", e.value.name);
                          form.setFieldValue("sex", e.value.sex);
                          form.setFieldValue(
                            "birthDate",
                            e.value.birthdate.split("-").reverse().join("/")
                          );
                          form.setFieldValue("condition", e.value.condition);
                          // form.setFieldValue("phoneNumber", e.value)
                          const phone = e.value.phone;
                          form.setFieldValue("phoneCode", phone.slice(0, 5));
                          form.setFieldValue("phoneNumber", phone.slice(5));
                          // phone
                          form.validateSync();

                          return;
                        }
                        field.handleChange(e.value);
                      }}
                      query={holderRMSQuery}
                      notFoundBtnLabel="Crear registro de asegurado"
                      notFoundBtnClick={() => {}}
                      itemTemplate={createSearchItemTemplate(selectHolder)}
                      overlayRef={holderSearchRef}
                      onSearchEnter={onHolderSearchEnter}
                    />
                  )}
                </form.Field>
              </div>
            </div>

            <div className="p-field">
              {/* Holder personal data */}
              <form.Field
                name="name"
                children={(field) => (
                  <div className="p-field mt-2">
                    <label htmlFor="holderName">Nombre completo</label>
                    <InputText
                      id="holderName"
                      name="holderName"
                      value={field.state.value}
                      onChange={(e) => field.handleChange(e.target.value)}
                      // setHolderForm({ ...holderForm, name: e.target.value })
                      invalid={field.state.meta.errors.length > 0}
                      disabled={holderQuery.isLoading}
                      ref={holderNameRef}
                    />
                    <FieldInfo field={field} />
                  </div>
                )}
              />

              {/* SEXO */}
              <form.Field
                name="sex"
                children={(field) => (
                  <div className="p-field">
                    <label htmlFor="holderSex">Sexo</label>
                    <SelectButton
                      id="holderSex"
                      name="holderSex"
                      value={field.state.value}
                      onChange={(e) => field.handleChange(e.target.value)}
                      // setHolderForm({ ...holderForm, sex: e.value })
                      options={GENDERS}
                      disabled={holderQuery.isLoading}
                      invalid={field.state.meta.errors.length > 0}
                    />
                    <FieldInfo field={field} />
                  </div>
                )}
              />

              <form.Field name="birthDate">
                {(field) => (
                  <div className="p-field">
                    <span>Fecha de nacimiento</span>
                    <VSieteDateInput
                      name="holderBirthdate"
                      value={field.state.value}
                      setValue={(value) => field.handleChange(value)}
                      // setHolderForm({ ...holderForm, birthDate: value })
                      disabled={holderQuery.isLoading}
                      invalid={field.state.meta.errors.length > 0}
                    />
                  </div>
                )}
              </form.Field>

              <form.Field name="condition">
                {(field) => (
                  <div className="p-field">
                    <label htmlFor="holderCondition">Condición</label>
                    <SelectButton
                      id="holderCondition"
                      name="holderCondition"
                      className="select-sex"
                      value={field.state.value}
                      onChange={(e) => field.handleChange(e.target.value)}
                      options={CONDITIONS}
                      invalid={field.state.meta.errors.length > 0}
                    />
                  </div>
                )}
              </form.Field>

              <div className="p-field">
                <label htmlFor="phoneNumber">Número de teléfono</label>
                <div className="field-with-select">
                  <form.Field name="phoneCode">
                    {(field) => (
                      <Dropdown
                        id="phoneCode"
                        name="phoneCode"
                        options={PHONE_CODES}
                        placeholder="Código"
                        value={field.state.value}
                        onChange={(e) => field.handleChange(e.value)}
                        disabled={patientQuery.isLoading}
                      />
                    )}
                  </form.Field>

                  <form.Field name="phoneNumber">
                    {(field) => (
                      <InputText
                        id="phoneNumber"
                        name="phoneNumber"
                        inputMode="numeric"
                        keyfilter="pint"
                        maxLength={7}
                        value={field.state.value}
                        onChange={(e) => field.handleChange(e.target.value)}
                        onPaste={(e) => {
                          const text = e.clipboardData.getData("text/plain");
                          const newText = text.replace(/\D/g, "");
                          if (newText.length === 11) {
                            const phoneCode = newText.slice(0, 4);
                            const result = PHONE_CODES.find((item) => {
                              return (
                                item.value === phoneCode ||
                                item.label === phoneCode
                              );
                            });

                            if (result) {
                              form.setFieldValue("phoneCode", result.value);
                              form.setFieldValue(
                                "phoneNumber",
                                newText.slice(4)
                              );
                            }
                            form.setFieldValue("phoneNumber", newText.slice(4));
                          }
                        }}

                        disabled={patientQuery.isLoading}
                      />
                    )}
                  </form.Field>
                </div>
              </div>

              <div className="p-field">
                <label htmlFor="phoneNumber">
                  Número de teléfono (opcional)
                </label>
                <div className="field-with-select">
                  <form.Field name="optionalPhoneCode">
                    {(field) => (
                      <Dropdown
                        id="phoneCode"
                        name="phoneCode"
                        options={PHONE_CODES}
                        placeholder="Código"
                        value={field.state.value}
                        onChange={(e) => field.handleChange(e.value)}
                        disabled={patientQuery.isLoading}
                      />
                    )}
                  </form.Field>

                  <form.Field name="optionalPhoneNumber">
                    {(field) => (
                      <InputText
                        id="optionalPhoneNumber"
                        name="optionalPhoneNumber"
                        inputMode="numeric"
                        keyfilter="pint"
                        maxLength={7}
                        value={field.state.value}
                        onChange={(e) => field.handleChange(e.target.value)}
                        onPaste={(e) => {
                          const text = e.clipboardData.getData("text/plain");
                          const newText = text.replace(/\D/g, "");

                          if (newText.length === 11) {
                            const phoneCode = newText.slice(0, 4);
                            const result = PHONE_CODES.find((item) => {
                              return (
                                item.value === phoneCode ||
                                item.label === phoneCode
                              );
                            });

                            if (result) {
                              form.setFieldValue(
                                "optionalPhoneCode",
                                result.value
                              );
                              form.setFieldValue(
                                "optionalPhoneNumber",
                                newText.slice(4)
                              );
                            }
                            form.setFieldValue(
                              "optionalPhoneNumber",
                              newText.slice(4)
                            );
                          }
                          e.preventDefault();
                        }}
                        disabled={patientQuery.isLoading}
                      />
                    )}
                  </form.Field>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* DATOS DEL PACIENTE */}
        {formStep === 2 && (
          <>
            {/* DATOS DEL PACIENTE */}
            <form.Field name="patient.isHolder">
              {(field) => (
                <div className="flex flex-row align-items-center gap-3 pt-2 mb-2">
                  <span>¿El titular es el paciente?</span>
                  <SelectButton
                    value={field.state.value}
                    onChange={(e) => field.handleChange(e.target.value)}
                    // setPatientForm({ ...patientForm, isHolder: e.value })
                    options={[
                      { label: "Si", value: true },
                      { label: "No", value: false },
                    ]}
                    disabled={patientQuery.isLoading || holderQuery.isLoading}
                    allowEmpty={false}
                  />
                </div>
              )}
            </form.Field>
            {!patientIsHolder && (
              <div className="">
                <FormSubHeading title="Datos del Paciente" />
                <div className="p-field">
                  <label htmlFor="patientCi">Cédula</label>
                  <div className="field-with-select">
                    <form.Field name="patient.ciType">
                      {(field) => (
                        <Dropdown
                          id={"patient" + field.name}
                          name={"patient" + field.name}
                          options={[
                            { label: "V", value: "V" },
                            { label: "MENOR", value: "M" },
                          ]}
                          placeholder="Tipo"
                          value={field.state.value}
                          onChange={(e) => field.handleChange(e.value)}
                          required
                        />
                      )}
                    </form.Field>
                    {patientCiType === "V" && (
                      <form.Field name="patient.ci">
                        {(field) => (
                          <InputWithSuggestions
                            inputName={field.name}
                            inputValue={field.state.value}
                            // inputDisabled={}
                            keyfilter="pint"
                            maxLength={9}
                            onCompleteMethod={searchPatient}
                            suggestions={patientResult}
                            onTextChange={(e) => {
                              if (e.value && e.value.hasOwnProperty("value")) {
                                field.handleChange(e.value.value);
                                form.setFieldValue(
                                  "patient.name",
                                  e.value.name
                                );
                                form.setFieldValue("patient.sex", e.value.sex);
                                form.setFieldValue(
                                  "patient.birthDate",
                                  e.value.birthdate
                                    .split("-")
                                    .reverse()
                                    .join("/")
                                );
                                form.setFieldValue(
                                  "patient.condition",
                                  e.value.condition
                                );
                                // form.setFieldValue("phoneNumber", e.value)
                                const phone = e.value.phone;
                                form.setFieldValue(
                                  "patient.phoneCode",
                                  phone.slice(0, 5)
                                );
                                form.setFieldValue(
                                  "patient.phoneNumber",
                                  phone.slice(5)
                                );
                                // phone
                                form.validateSync();

                                return;
                              }
                              field.handleChange(e.value);
                            }}
                            query={holderQuery}
                            notFoundBtnLabel="Crear registro de asegurado"
                            notFoundBtnClick={() => {}}
                            itemTemplate={createSearchItemTemplate(
                              selectHolder
                            )}
                            overlayRef={holderSearchRef}
                            onSearchEnter={onHolderSearchEnter}
                          />
                        )}
                      </form.Field>
                    )}
                    {patientCiType === "M" && (
                      <form.Field name="patient.minorId">
                        {(field) => (
                          <Dropdown
                            id="minorId"
                            name="minorId"
                            options={dependency}
                            placeholder="Dependencia"
                            optionLabel="name"
                            optionValue="code"
                            value={field.state.value}
                            onChange={(e) => field.handleChange(e.value)}
                          />
                        )}
                      </form.Field>
                    )}
                  </div>
                </div>

                <form.Field
                  name="patient.name"
                  children={(field) => (
                    <div className="p-field mt-2">
                      <label htmlFor="patientName">Nombre completo</label>
                      <InputText
                        id="patientName"
                        name="patientName"
                        value={field.state.value}
                        onChange={(e) => field.handleChange(e.target.value)}
                        invalid={field.state.meta.errors.length > 0}
                        ref={holderNameRef}
                      />
                      <FieldInfo field={field} />
                    </div>
                  )}
                />

                <form.Field
                  name="patient.sex"
                  children={(field) => (
                    <div className="p-field">
                      <label htmlFor="patientSex">Sexo</label>
                      <SelectButton
                        id="patientSex"
                        name="patientSex"
                        value={field.state.value}
                        onChange={(e) => field.handleChange(e.target.value)}
                        options={GENDERS}
                        invalid={field.state.meta.errors.length > 0}
                      />
                      <FieldInfo field={field} />
                    </div>
                  )}
                />

                <form.Field name="patient.birthDate">
                  {(field) => (
                    <div className="p-field">
                      <span>Fecha de nacimiento</span>
                      <VSieteDateInput
                        name="patientBirthdate"
                        value={field.state.value}
                        setValue={(value) => field.handleChange(value)}
                        invalid={field.state.meta.errors.length > 0}
                      />
                    </div>
                  )}
                </form.Field>
              </div>
            )}
          </>
        )}

        {formStep === 3 && (
          <div>
            {/* SELECCIÓN DE DIAGNÓSTICO */}
            <FormSubHeading title="Diagnóstico" />
            <form.Field name="patient.diagnosis">
              {(field) => (
                <div className="p-field">
                  <label htmlFor="diagnosis">Diagnóstico</label>
                  <MultiSelect
                    id="diagnosis"
                    name="diagnosis"
                    value={field.state.value}
                    onChange={(e) => field.handleChange(e.value)}
                    options={diagnosisQuery.data ?? []}
                    optionValue="id"
                    optionLabel="name"
                    placeholder="Seleccionar diagnóstico"
                    filter
                    loading={diagnosisQuery.isLoading}
                    disabled={diagnosisQuery.isLoading}
                    virtualScrollerOptions={{
                      itemSize: 48,
                      style: { width: "650px" },
                    }}
                  />
                </div>
              )}
            </form.Field>

            {/* SELECCIÓN DE FILIAL */}
            <FormSubHeading title="FILIAL" />
            <form.Field name="patient.subsidiary">
              {(field) => (
                <div className="p-field">
                  <label htmlFor="subsidiary">Filial</label>
                  <Dropdown
                    id="subsidiary"
                    name="subsidiary"
                    options={subsidiariesQuery.data ?? []}
                    value={field.state.value}
                    onChange={(e) => {
                      field.handleChange(e.value);
                      form.setFieldValue(
                        "patient.client",
                        subsidiariesQuery.data.find(
                          (subsidiary) => subsidiary.id === e.value
                        )?.company_client ?? null
                      );
                    }}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Seleccionar filial"
                    filter
                    disabled={
                      patientQuery.isLoading || subsidiariesQuery.isLoading
                    }
                    loading={subsidiariesQuery.isLoading}
                  />
                </div>
              )}
            </form.Field>

            {!!requirementList.length && (
              <>
                {/* TABLA DE REQUERIMIENTOS */}
                <FormSubHeading title="Requerimientos" />
                <RequirementDataView
                  requirements={requirementList}
                  setRequirementList={setRequirementList}
                  setCenterSelected={setSelectedMedicalCenter}
                />
              </>
            )}

            <Button
              type="button"
              icon="pi pi-plus"
              label="Añadir requerimiento"
              className="mt-2 mb-2"
              severity="info"
              disabled={false}
              loading={newCaseMutation.isPending}
              onClick={() => {
                setEditValues(null);
                setShowRequirementModal(true);
              }}
            />
          </div>
        )}
        {/* BOTONES STEP */}
        {formStep === 1 && (
          <form.Subscribe
            selector={(state) => [
              state.canSubmit,
              state.isSubmitting,
              state.errorMap,
            ]}
            children={([canSubmit, isSubmitting, errors]) => (
              <div className="flex gap-2 justify-between mt-2">
                <Button
                  type="button"
                  icon="pi pi-arrow-left"
                  label="Volver"
                  className=""
                  severity="secondary"
                  raised
                  disabled={formStep === 1}
                  onClick={() => {}}
                />

                <Button
                  type="button"
                  icon="pi pi-arrow-right"
                  label="Siguiente"
                  tooltip="Complete los datos"
                  tooltipOptions={{ position: "top" }}
                  className=""
                  iconPos="right"
                  disabled={!canSubmit}
                  onClick={() => {
                    form.setFieldValue("step", formStep + 1);
                    form.validateSync();
                  }}
                />
              </div>
            )}
          />
        )}
        {formStep === 2 && (
          <form.Subscribe
            selector={(state) => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting, errors]) => (
              <div className="flex gap-2 justify-between mt-2">
                <Button
                  type="button"
                  icon="pi pi-arrow-left"
                  label="Volver"
                  className=""
                  severity="secondary"
                  raised
                  disabled={formStep === 1}
                  onClick={() => {
                    form.setFieldValue("step", formStep - 1);
                    form.validateSync();
                  }}
                />

                <Button
                  type="button"
                  icon="pi pi-arrow-right"
                  label="Siguiente"
                  tooltip="Complete los datos"
                  tooltipOptions={{ position: "top" }}
                  className=""
                  iconPos="right"
                  disabled={!canSubmit}
                  onClick={() => {
                    form.setFieldValue("step", formStep + 1);
                    form.validateSync();
                  }}
                />
              </div>
            )}
          />
        )}

        {formStep === 3 && (
          <form.Subscribe
            selector={(state) => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting, errors]) => (
              <div className="flex gap-2 justify-between mt-2">
                <Button
                  type="button"
                  icon="pi pi-arrow-left"
                  label="Volver"
                  className=""
                  severity="secondary"
                  raised
                  disabled={formStep === 1}
                  onClick={() => {
                    form.setFieldValue("step", formStep - 1);
                    form.validateSync();
                  }}
                />

                <Button
                  type="button"
                  icon="pi pi-save"
                  label="Guardar Caso"
                  tooltip="Complete los datos"
                  tooltipOptions={{ position: "top" }}
                  className=""
                  iconPos="right"
                  disabled={!canSubmit}
                  loading={newCaseMutation.isPending}
                  onClick={() => {
                    form.handleSubmit();
                  }}
                />
              </div>
            )}
          />
        )}
      </form>

      {!!canSaveCase && (
        <Button
          type="button"
          icon="pi pi-save"
          label="Guardar caso"
          className="mt-4"
          severity="success"
          loading={newCaseMutation.isPending}
          disabled={newCaseMutation.isPending}
          onClick={() => newCaseMutation.mutate()}
        />
      )}

      {/* MODAL DE REQUERIMIENTOS */}
      <RequirementModal
        showModal={showRequirementModal}
        onHide={() => {
          if (!showRequirementModal) return;
          setEditValues(null);
          setShowRequirementModal(false);
        }}
        setRequirementList={setRequirementList}
        setInputsLocked={setInputsLocked}
        initialValues={editValues}
        patientClient={patientClient}
        centerSelected={selectedMedicalCenter}
        setCenterSelected={setSelectedMedicalCenter}
      />

      {/* MODAL DE CONFIRMACIÓN DE DUPLICADO */}
      <Dialog
        header="Confirmar duplicado"
        visible={showDuplicateModal}
        style={{ width: "50vw" }}
        footer={duplicateModalFooter}
        onHide={() => setShowDuplicateModal(false)}
      >
        {duplicateModalContent}
      </Dialog>
    </div>
  );
};

export default FormComponent;
