import { useEffect, useRef, useState } from "react";
import { FilterMatchMode } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { FileUpload } from "primereact/fileupload";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Toast } from "primereact/toast";

import Dashboard from "../Layout/Dashboard";
import { useParams } from "react-router-dom";
import FormSubHeading from "../components/FormSubHeading";
import {
  currencyFormat,
  formatCi,
  formatDateString,
  formatFullNumber,
  readableSex,
} from "../utils";

import { useRequirementTypes, useSingleMedicalCase } from "../hooks/queries";
import { UserRole } from "../constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BASE_URL, CaseStatus, MEDICAL_FILE_TYPES } from "../constants";
import { ChangeStatusSingleModal } from "../components/StatusChangeModal";
import { usePatchMedicalCase } from "../hooks/mutations";
import {
  deleteMedicalCaseInvoice,
  updateCaseRequirementsDone,
} from "../services/medicalCases";
// import { StatusSelector } from "../components/StatusChangeSelector";
import StatusTag from "../components/StatusTag";
import "./CaseDetail.css";
import HospitalPendingModal from "./HospitalPendingModal";
import {useSuscribeCaseToPendingMutation} from "../hooks/mutations";

// import { Timeline } from "primereact/timeline";

/**
 * Detalle del caso médico
 */
export default function CaseDetail({ backButtonUrl }) {
  // Buscar detalle del caso
  const { caseId } = useParams();
  const { data, isLoading } = useSingleMedicalCase(caseId);

  return (
    <Dashboard
      headerTitle="Detalle del caso"
      backButtonUrl={backButtonUrl ?? "/casos"}
    >
      {isLoading && <ProgressSpinner />}
      {data && !isLoading && (
        <CaseDetailContent data={data} isLoading={isLoading} />
      )}
      {!data && !isLoading && <p>No se encontró el caso médico</p>}
    </Dashboard>
  );
}

/**
 * Returns the difference in days between a date and the current
 * @param {Date} date
 */
function dateDiffFromNow(date) {
  const currentDate = new Date();

  // Calculate the difference in time (in milliseconds)
  const timeDifference = currentDate - date;

  // Convert the difference from milliseconds to days
  return Math.floor(timeDifference / (1000 * 60 * 60 * 24));
}

/**
 * Show detail when case is loaded
 * @param {object} props
 * @param {import("../services/medicalCases").MedicalCase} props.data
 */
function CaseDetailContent({ data, isLoading }) {
  const [requirementsDone, setRequirementsDone] = useState(
    data.requirements.map((req) => ({
      id: req.id,
      requirement: req.requirement,
      is_done: req.is_done,
      amount: req.amount,
    }))
  );
  const role = localStorage.getItem("role");

  // State for dialog
  const [showFileDialog, setShowFileDialog] = useState(false);

  // State for medical files
  const [medicalFiles, setMedicalFiles] = useState(
    data.files.map((file) => ({
      file: file.file,
      fileName: file.file_name,
      fileType: file.type_requirement,
    }))
  );

  // State for invoice
  const [invoiceFile, setInvoiceFile] = useState(null);

  // read changes in data
  const [invoice, setInvoice] = useState(
    data.invoice.map((invoice) => ({
      id: invoice.id,
      file: invoice.file,
      fileName: invoice.file_name,
    }))
  );

  // State for dialog
  const [showDialog, setShowDialog] = useState(false);
  const [showReqDoneDialog, setShowReqDoneDialog] = useState(false);
  const [showRemesadoDialog, setShowRemesadoDialog] = useState(false);
  const [showDialogPending, setShowDialogPending] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState(data.status);
  const [showAnullateDialog, setShowAnullateDialog] = useState(false);
  const [showSubscribeDialog, setshowSubscribeDialog] = useState(false);
  const [messageSubscribeResponse, setMessageSubscribeResponse] = useState("");

  // Initialize state for requirement state
  useEffect(() => {
    setSelectedStatus(data.status);
  }, [data]);

  const toastRef = useRef(null);

  // Change requirement done state
  const changeRequirementDone = (requirement, done) => {
    console.log(requirement, done);
    const newRequirementsDone = requirementsDone.map((req) => {
      if (req.id === requirement) {
        return {
          ...req,
          is_done: done,
        };
      }
      return req;
    });
    setRequirementsDone(newRequirementsDone);
  };

  /**
   * Save medical file from dialog
   * @param {{ fileType: string, file: File & { objectURL: string } }} medicalFile
   */
  const onSaveMedicalFile = (medicalFile) => {
    setMedicalFiles((prev) => [...prev, medicalFile]);
  };

  const queryClient = useQueryClient();

  const caseMutation = usePatchMedicalCase({
    onSuccess: ({ type, message }) => {
      if (type === "success") {
        toastRef.current.show({
          severity: "success",
          summary: "Éxito",
          detail: message,
        });
        queryClient.invalidateQueries({
          queryKey: ["medicalCase", Number(data.id)],
        });
      } else {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: message,
        });
        setSelectedStatus(data.status);
      }
      setShowDialog(false);
      setShowRemesadoDialog(false);
      setShowAnullateDialog(false);
    },
  });

  const requirementsDoneMutation = useMutation({
    mutationFn: updateCaseRequirementsDone,
    onSuccess: ({ type, message }) => {
      if (type === "success") {
        toastRef.current.show({
          severity: "success",
          summary: "Éxito",
          detail: message,
        });
        queryClient.invalidateQueries({
          queryKey: ["medicalCase", data.id],
        });

        setShowReqDoneDialog(false);
        setShowRemesadoDialog(false);

        // setSelectedStatus(CaseStatus.ATENDIDO);
      } else {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: message,
        });
        setShowReqDoneDialog(false);
        setShowRemesadoDialog(false);
      }
    },
  });


  const suscribeCaseToPendingMutation = useSuscribeCaseToPendingMutation({
    onSuccess: ({ isOk, message }) => {
      setshowSubscribeDialog(true);
      if (message === "Asegurado No Existe") {
        setMessageSubscribeResponse("Paciente pendiente por suscribir");
        return;
      }
      setMessageSubscribeResponse(message);
    },
  });

  const deleteInvoiceMutation = useMutation({
    mutationFn: deleteMedicalCaseInvoice,
    onSuccess: ({ type, message, id }) => {
      if (type === "success") {
        toastRef.current.show({
          severity: "success",
          summary: "Éxito",
          detail: "Archivo eliminado con éxito",
        });
        queryClient.invalidateQueries({
          queryKey: ["medicalCase", data.id],
        });
        setInvoice((prev) => prev.filter((inv) => inv.id !== id));
      } else {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: message,
        });
      }
    },
  });

  /**
   * Show preview of an invoice file
   * @param {{ file: string, fileName: string } | undefined} row
   */
  const invoiceFilePreviewBody = (row) => {
    if (
      row?.fileName !== undefined &&
      (row.fileName?.endsWith(".jpeg") ||
        row.fileName?.endsWith(".jpg") ||
        row.fileName?.endsWith(".png"))
    ) {
      return (
        <img
          role="presentation"
          src={row.file}
          alt={row.fileName}
          width={80}
        ></img>
      );
    } else {
      return <></>;
    }
  };

  /**
   * Show actions for a medical file
   * @param {{ file: string, fileName: string }} row
   */
  const medicalFileActionBody = (row) => (
    <div className="flex align-content-center gap-1">
      <Button
        label="Ver"
        className="max-w-fit"
        outlined
        onClick={() => window.open(row.file, "_blank")}
      />
      {(data.status === CaseStatus.PENDIENTE ||
        data.status === CaseStatus.ATENDIDO ||
        data.status === CaseStatus.RESCHEDULED) &&
        role === UserRole.MEDICAL_CENTER && (
          <Button
            label="Eliminar"
            className="max-w-fit"
            outlined
            text
            severity="danger"
            aria-label="Eliminar"
            onClick={() => {
              setMedicalFiles((prev) => {
                return prev.filter((file) => file.file !== row.file);
              });
              if (row.id) {
                // Delete file
                deleteInvoiceMutation.mutate({ invoiceId: row.id });
              }
            }}
          />
        )}
    </div>
  );

  const medicalFileNameLinkBody = (row) => (
    <Button
      className="text-left"
      label={row.fileName}
      link
      onClick={() => window.open(row.file, "_blank")}
    />
  );

  /**
   * Show toast for successful upload
   */
  const onUpload = () => {
    toastRef.current.show({
      severity: "success",
      summary: "Éxito",
      detail: "Archivo subido con éxito",
    });
  };

  return (
    <div className="p-fluid">
      {data.status === "pendiente" && (
        <HospitalPendingModal
          visible={showDialogPending}
          onHide={() => {
            if (showDialogPending) setShowDialogPending(false);
          }}
          patientName={data.patient_.name}
          phoneNumber={data.patient_.phone}
          daysLeft={3 - dateDiffFromNow(new Date(data.created_at))}
          onAccept={() => {
            caseMutation.mutate({
              caseId: data.id,
              data: { status: CaseStatus.RESCHEDULED },
            });
            if (showDialogPending) setShowDialogPending(false);
          }}
          loading={caseMutation.isLoading}
        />
      )}

      <div className="flex justify-content-between mb-3 mr-3">
        <h3>
          <i className="pi pi-ticket"></i> Número de Orden: {data.id}
        </h3>
        {/* <div>
          <Timeline
            value={events}
            opposite={(item) => (
              <StatusTag className="text-base" value={item.status} />
            )}
            layout="horizontal"
            align="top"
            marker={customizedMarker} 
            content={(item) => (
              <small className="text-color-secondary">{item.date}</small>
            )}
          />
        </div> */}
        <div className="flex flex-column align-items-center justify-content-center text-lg">
          Estado:
          <StatusTag className="text-base" value={selectedStatus} />
          {/* <StatusSelector
            currentStatus={data.status}
            selectedStatus={selectedStatus}
            disabled={true}
            onChange={(e) => {
              if (e.value === "pendiente") {
                toastRef.current.show({
                  severity: "error",
                  summary: "Error",
                  detail: "No se puede cambiar a estado pendiente",
                });
                return;
              }
              if (e.value === CaseStatus.REMESADO && !invoiceFile) {
                toastRef.current.show({
                  severity: "error",
                  summary: "Error",
                  detail: "Debe subir la factura y el soporte médico para remsesar el caso",
                });
                return;
              }
              setSelectedStatus(e.value);
              setShowDialog(true);
            }}
          /> */}
        </div>
      </div>
      <Toast ref={toastRef} position="top-center" />

      <>
        <FormSubHeading title="Creador del caso" />
        <div>
          <p className="m-2">
            <i className="pi pi-user"></i> <strong>Nombre:</strong>{" "}
            {data.creator_user.first_name} {data.creator_user.last_name}
          </p>
          <p className="m-2">
            <i className="pi pi-envelope"></i> <strong>Email:</strong>{" "}
            {data.creator_user.email}
          </p>
        </div>
      </>

      <div className="flex justify-content-between">
        <div className="m-1 flex-1">
          <FormSubHeading title="Datos del paciente" />
          <div className="">
            <p className="m-2">
              <i className="pi pi-id-card"></i> <strong>Cédula:</strong>{" "}
              {formatCi(data.patient_.ci)}
            </p>
            <p className="m-2">
              <i className="pi pi-address-book"></i> <strong>Nombre:</strong>{" "}
              {data.patient_.name}
            </p>
            <p className="m-2">
              <i className="pi pi-phone"></i> <strong>Teléfono:</strong>{" "}
              {formatFullNumber(data.patient_.phone)}
            </p>
            <p className="m-2">
              <i className="pi pi-phone"></i> <strong>Otro Teléfono:</strong>{" "}
              {formatFullNumber(data.patient_.optional_phone)}
            </p>
            <p className="m-2">
              <i className="pi pi-calendar"></i>{" "}
              <strong>Fecha de Nacimiento:</strong>{" "}
              {formatDateString(data.patient_.birthdate)}
            </p>
            <p className="m-2">
              <i className="pi pi-user"></i> <strong>Sexo:</strong>{" "}
              {readableSex(data.patient_.sex)}
            </p>
          </div>
        </div>
        <div className="m-1 flex-1">
          <FormSubHeading title="Datos del caso y servicios" />
          <div className="mb-4">
            <p className="m-2">
              <i className="pi pi-briefcase"></i> <strong>Filial:</strong>{" "}
              {data.subsidiary_name}
            </p>
            <p className="m-2">
              <i className="pi pi-wave-pulse"></i> <strong>Diagnóstico:</strong>{" "}
              {data.diagnosis_name}
            </p>
            <p className="m-2">
              <i className="pi pi-building-columns"></i>{" "}
              <strong>Proveedor:</strong> {data.medical_center_name}
            </p>
            {/* <p className="m-2">
          <i className="pi pi-money-bill"></i> <strong>Monto:</strong> Bs.{" "}
          {currencyFormat(data.amount)}
        </p> */}
          </div>
        </div>
      </div>
      <FormSubHeading title="Datos del titular" />
      <div>
        <p>
          <i className="pi pi-id-card"></i> <strong>Cédula:</strong>{" "}
          {formatCi(data.holder_.ci)}
        </p>
      </div>

      <FormSubHeading title="Tilda los servicios atendidos y descarga el compromiso" />
      <div>
        <DetailRequirementsTable
          value={data.requirements}
          showDoneColumn={data.status !== "anulado"}
          requirementsDone={requirementsDone}
          changeRequirementDone={changeRequirementDone}
          caseStatus={data.status}
        />
        {role !== UserRole.ANALYST_COMPANY_CLIENT &&
          role !== UserRole.ANALYST_SUPPLIER &&
          (data.status === CaseStatus.PENDIENTE ||
            data.status === CaseStatus.RESCHEDULED) && (
            <div className="flex justify-content-end mb-3">
              <Button
                tooltip="Marcar servicios para atender"
                tooltipOptions={{
                  position: "top",
                  showOnDisabled: true,
                  disabled: requirementsDone.filter((r) => r.is_done).length,
                }}
                severity="success"
                className="max-w-max mt-3"
                icon="pi pi-save"
                label="Marcar como atendido"
                disabled={!requirementsDone.filter((r) => r.is_done).length}
                onClick={() => {
                  setShowReqDoneDialog(true);
                }}
              />
            </div>
          )}
        {data.status !== CaseStatus.PENDIENTE &&
          data.status !== CaseStatus.TO_REVIEW &&
          data.status !== CaseStatus.TO_SUBSCRIBE &&
          data.status !== CaseStatus.RESCHEDULED &&
          data.status !== CaseStatus.ANULADO &&
          (role !== UserRole.ANALYST_COMPANY_CLIENT ||
            role !== UserRole.ANALYST_SUPPLIER) && (
            <div className="flex justify-content-end mb-3">
              <Button
                severity="success"
                className="max-w-max mt-3"
                icon="pi pi-external-link"
                label="Ver Compromiso"
                disabled={!requirementsDone.length}
                onClick={() => {
                  // open external link
                  window.open(
                    `${BASE_URL}insurance/medical-case-compromise/${data.id}/`
                  );
                  // setShowReqDoneDialog(true);
                }}
              />
            </div>
          )}
      </div>

      {/* if */}
      {role !== UserRole.ANALYST_COMPANY_CLIENT &&
        data.status !== CaseStatus.PENDIENTE &&
        data.status !== CaseStatus.RESCHEDULED && (
          <>
            <FormSubHeading title="Factura" />
            <div className="mb-4">
              {role !== UserRole.ANALYST_SUPPLIER && (
                <FileUpload
                  disabled={data.status === CaseStatus.ANULADO}
                  name="file"
                  mode="basic"
                  chooseLabel={
                    data.status === CaseStatus.ANULADO
                      ? "No disponible"
                      : "Cargar factura"
                  }
                  onSelect={(e) => {
                    // console.log(setInvoice);
                    if (e.files.length) {
                      setInvoiceFile(e.files[0]);
                    } else {
                      setInvoiceFile(null);
                    }
                  }}
                  url={`${BASE_URL}insurance/medical-case-invoice/`}
                  auto
                  accept=".jpeg,.jpg,.png,.pdf"
                  onBeforeUpload={({ formData, xhr }) => {
                    formData.append("medical_case", data.id);
                    console.log(xhr.response);
                    const reqListener = (r) => {
                      console.log("rrr", r);
                      console.log(r.target.response);
                      // convert the JSON string to a JSON object
                      const data = JSON.parse(r.target.response);
                      console.log(data);
                      setInvoice((prev) => [
                        ...prev,
                        { file: data.file, fileName: data.file_name },
                      ]);
                    };
                    xhr.addEventListener("load", reqListener);
                  }}
                  onUpload={onUpload}
                  onBeforeSend={(e) => {
                    console.log("onBeforeSend", e);
                  }}
                  maxFileSize={5000000} // 5MB
                  invalidFileSizeMessageDetail="El tamaño máximo permitido es 5MB"
                />
              )}
              {!!invoice.length && (
                <DataTable stripedRows value={invoice} className="mt-2">
                  <Column header="Vista previa" body={invoiceFilePreviewBody} />
                  {/* <Column header="Nombre" field="fileName" /> */}
                  <Column header="Nombre" body={medicalFileNameLinkBody} />
                  <Column header="Acción" body={medicalFileActionBody} />
                </DataTable>
              )}
            </div>

            <FormSubHeading title="Cargar soportes médicos" />
            {role !== UserRole.ANALYST_SUPPLIER && (
              <Button
                label="Agregar soporte"
                onClick={() => setShowFileDialog(true)}
                icon="pi pi-file-arrow-up"
                className="max-w-max"
                disabled={data.status === CaseStatus.ANULADO}
              />
            )}
            <MedicalFileDialog
              show={showFileDialog}
              onHide={() => {
                if (showFileDialog) setShowFileDialog(false);
              }}
              onSave={onSaveMedicalFile}
              onUpload={onUpload}
              caseId={data.id}
            />
          </>
        )}

      <div className="mb-4">
        {!!medicalFiles.length && (
          <DataTable stripedRows value={medicalFiles} className="mt-2">
            <Column header="Vista previa" body={invoiceFilePreviewBody} />
            <Column header="Nombre" body={medicalFileNameLinkBody} />

            <Column
              header="Tipo"
              field="fileType"
              body={(row) => {
                const match = MEDICAL_FILE_TYPES.find(
                  (type) => type.value === row.fileType
                );
                return match?.label ?? "Otro";
              }}
            />
            <Column header="Acción" body={medicalFileActionBody} />
          </DataTable>
        )}
      </div>

      <div className="flex justify-content-end">
        <div className="flex flex-column align-items-center">
          {/* Cambiar estado:{" "} */}
          {/* <StatusSelector
            currentStatus={data.status}
            selectedStatus={selectedStatus}

            disabled={true}
            onChange={(e) => {
              if (e.value === "pendiente") {
                toastRef.current.show({
                  severity: "error",
                  summary: "Error",
                  detail: "No se puede cambiar a estado pendiente",
                });
                return;
              }
              if (e.value === CaseStatus.REMESADO && !invoiceFile && !medicalFiles.length) {
                toastRef.current.show({
                  severity: "error",
                  summary: "Error",
                  detail: "Debe subir la factura y el soporte médico para remsesar el caso",
                });
                return;
              }
              setSelectedStatus(e.value);
              setShowDialog(true);
            }}
          /> */}
        </div>
      </div>
      <div className="w-100 flex flex justify-content-end">
        {(role === UserRole.CALL_CENTER || role === UserRole.MASTER) &&
          data.status === CaseStatus.TO_SUBSCRIBE && (
            <Button
              icon="pi pi-bell"
              label="Suscribir"
              severity="warning"
              onClick={() => {
                // set timeout

                suscribeCaseToPendingMutation.mutate({
                  caseId: data.id,
                });
              }}
              loading={suscribeCaseToPendingMutation.isPending}
              disabled={suscribeCaseToPendingMutation.isPending}
              className="mt-3 ml-2 max-w-max align-self-end"
            />
          )}

        {(role === UserRole.CALL_CENTER ||
          role === UserRole.MASTER ||
          role === UserRole.MEDICAL_CENTER) && (
          <Button
            icon="pi pi-times"
            label="Anular Caso"
            onClick={() => setShowAnullateDialog(true)}
            severity="danger"
            className="mt-3 ml-2 max-w-max align-self-end"
            disabled={
              !(
                data.status === CaseStatus.PENDIENTE ||
                data.status === CaseStatus.RESCHEDULED ||
                data.status === CaseStatus.TO_SUBSCRIBE
              )
            }
          />
        )}
        {data.status === CaseStatus.ATENDIDO &&
          role !== UserRole.ANALYST_COMPANY_CLIENT &&
          role !== UserRole.ANALYST_SUPPLIER && (
            <Button
              icon="pi pi-save"
              label="Remesar a La Mundial"
              onClick={() => {
                if ((!invoice.length && !invoiceFile) || !medicalFiles.length) {
                  toastRef.current.show({
                    severity: "error",
                    summary: "Error",
                    detail:
                      "Debe subir la factura y el soporte médico para remsesar el caso",
                  });
                  return;
                }
                setShowRemesadoDialog(true);
              }}
              severity="success"
              className="mt-3 ml-2 max-w-max align-self-end"
              disabled={data.status === CaseStatus.ANULADO}
            />
          )}
      </div>
      <div className="w-100 flex flex justify-content-end"></div>

      {/* Requirements done modal */}
      <Dialog
        header="Marcar servicios como atendidos"
        visible={showReqDoneDialog}
        style={{ maxWidth: "35rem" }}
        onHide={() => {
          if (showReqDoneDialog) setShowReqDoneDialog(false);
        }}
        draggable={false}
      >
        <div className="p-fluid text-center">
          <p>¿Seguro que deseas marcar estos servicios como atendidos?</p>
          <p>
            <b>
              Esto cambiará el estado del caso de{" "}
              <StatusTag value={selectedStatus} /> a{" "}
              <StatusTag value={CaseStatus.ATENDIDO} />
            </b>
          </p>
        </div>
        <br></br>
        <div className="delete-confirm-buttons-container">
          <Button
            type="button"
            label="Confirmar"
            loading={requirementsDoneMutation.isPending}
            disabled={requirementsDoneMutation.isPending}
            onClick={() => {
              requirementsDoneMutation.mutate({
                caseId: data.id,
                doneRequirements: requirementsDone,
              });
            }}
          />
          <Button
            type="button"
            label="Cancelar"
            style={{ backgroundColor: "#999999", borderColor: "#999999" }}
            disabled={requirementsDoneMutation.isPending}
            onClick={() => {
              if (showReqDoneDialog) setShowReqDoneDialog(false);
            }}
          />
        </div>
      </Dialog>

      {/* Modal Remesado */}
      <Dialog
        header="Remesar a la mundial"
        visible={showRemesadoDialog}
        style={{ maxWidth: "35rem" }}
        onHide={() => {
          if (showRemesadoDialog) setShowRemesadoDialog(false);
        }}
        draggable={false}
      >
        <div className="p-fluid text-center">
          <p>
            <b>
              Esto cambiará el estado del caso de{" "}
              <StatusTag value={CaseStatus.ATENDIDO} /> a{" "}
              <StatusTag value={CaseStatus.REMESADO} />
            </b>
          </p>
        </div>
        <br></br>
        <div className="delete-confirm-buttons-container">
          <Button
            type="button"
            label="Confirmar"
            loading={requirementsDoneMutation.isPending}
            disabled={requirementsDoneMutation.isPending}
            onClick={() => {
              caseMutation.mutate({
                caseId: data.id,
                data: { status: CaseStatus.REMESADO },
              });
            }}
          />
          <Button
            type="button"
            label="Cancelar"
            style={{ backgroundColor: "#999999", borderColor: "#999999" }}
            disabled={requirementsDoneMutation.isPending}
            onClick={() => {
              if (showRemesadoDialog) setShowRemesadoDialog(false);
            }}
          />
        </div>
      </Dialog>

      {/* Modal for confirming case status change */}
      <ChangeStatusSingleModal
        rowChanging={data}
        newStatus={selectedStatus}
        show={showDialog}
        onHide={() => {
          if (showDialog) setShowDialog(false);
        }}
        onSave={() => {
          caseMutation.mutate({
            caseId: data.id,
            data: { status: selectedStatus },
          });
        }}
      />

      {/* Modal Anullate */}
      <Dialog
        header="Anular Caso"
        visible={showAnullateDialog}
        style={{ maxWidth: "35rem" }}
        onHide={() => {
          if (showAnullateDialog) setShowAnullateDialog(false);
        }}
        draggable={false}
      >
        <div className="p-fluid text-center">
          <p>
            <b>
              Esto cambiará el estado del caso a{" "}
              <StatusTag value={CaseStatus.ANULADO} />
            </b>
          </p>
        </div>
        <br></br>
        <div className="delete-confirm-buttons-container">
          <Button
            type="button"
            label="Confirmar"
            loading={requirementsDoneMutation.isPending}
            disabled={requirementsDoneMutation.isPending}
            onClick={() => {
              caseMutation.mutate({
                caseId: data.id,
                data: { status: CaseStatus.ANULADO },
              });
            }}
          />
          <Button
            type="button"
            label="Cancelar"
            style={{ backgroundColor: "#999999", borderColor: "#999999" }}
            disabled={requirementsDoneMutation.isPending}
            onClick={() => {
              if (showAnullateDialog) setShowAnullateDialog(false);
            }}
          />
        </div>
      </Dialog>

      {/* Modal Subscribe */}
      <Dialog
        header="Suscripción"
        visible={showSubscribeDialog}
        style={{ maxWidth: "35rem" }}
        onHide={() => {
          if (showSubscribeDialog) setshowSubscribeDialog(false);
        }}
        draggable={false}
      >
        <div className="p-fluid text-center">
          <p>{messageSubscribeResponse}</p>
        </div>
        <br></br>
        <div className="delete-confirm-buttons-container">
          <Button
            type="button"
            label="Aceptar"
            style={{ backgroundColor: "#999999", borderColor: "#999999" }}
            onClick={() => {
              if (showSubscribeDialog) setshowSubscribeDialog(false);
            }}
          />
        </div>
      </Dialog>
    </div>
  );
}

/**
 * Displays requirements of case in a table
 */
function DetailRequirementsTable({
  value,
  showDoneColumn,
  requirementsDone,
  changeRequirementDone,
  caseStatus,
}) {
  // Table filters
  const [filters] = useState({
    requirement_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    type_requirement: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  // Get requirement types
  const requirementTypesQuery = useRequirementTypes();
  const role = localStorage.getItem("role");

  /**
   * Dropdown to filter case requirement type
   */
  const requirementTypeFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      loading={requirementTypesQuery.isLoading}
      disabled={requirementTypesQuery.isLoading}
      options={requirementTypesQuery.data ?? []}
      optionValue="name"
      optionLabel="name"
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder="Seleccionar tipo"
      className="p-column-filter"
      showClear
      filter
      style={{ maxWidth: "10rem" }}
    />
  );

  /*
   * Calculate totales de los requerimientos
   */
  const thisAmountTotal = () => {
    // console.log(requirementsDone);
    // sumar un arreglo de objetos
    const total = requirementsDone
      .filter((requirement) => requirement.is_done)
      .reduce(
        (accumulator, requirement) => accumulator + Number(requirement.amount),
        0
      );
    return currencyFormat(total);
  };
  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Total:"
          colSpan={2}
          footerStyle={{ textAlign: "right" }}
        />
        <Column footer={thisAmountTotal} />
        <Column footer={null} />
      </Row>
    </ColumnGroup>
  );
  return (
    <DataTable
      stripedRows
      value={value}
      emptyMessage="No ha registrado servicios para este proveedor"
      dataKey="requirement"
      filterDisplay="row"
      filters={filters}
      removableSort
      footerColumnGroup={
        role !== UserRole.ANALYST_COMPANY_CLIENT ? footerGroup : null
      }
    >
      <Column
        header="Servicio"
        field="requirement_name"
        sortable
        filter
        showFilterMenu={false}
        filterPlaceholder="Buscar por servicio"
      />
      <Column
        header="Tipo de servicio"
        field="type_requirement"
        sortable
        filter
        filterElement={requirementTypeFilterTemplate}
        showFilterMenu={false}
        filterPlaceholder="Buscar tipo de servicio"
      />
      {role !== UserRole.ANALYST_COMPANY_CLIENT && (
        <Column
          header="Monto (Bs.)"
          field="amount"
          body={(row) => currencyFormat(row.amount)}
          sortable
        />
      )}
      {showDoneColumn && (
        <Column
          header="¿Atendido?"
          headerTooltip="Marcar servicio como atendido"
          headerTooltipOptions={{ position: "top" }}
          bodyClassName="text-center"
          body={(row) => (
            <Checkbox
              inputId={`Requerimiento ${row.requirement_name} completado`}
              checked={
                requirementsDone.find((req) => req.id === row.id)?.is_done
              }
              disabled={
                (caseStatus !== CaseStatus.PENDIENTE &&
                  caseStatus !== CaseStatus.RESCHEDULED) ||
                role === UserRole.ANALYST_COMPANY_CLIENT
              }
              onChange={(e) => changeRequirementDone(row.id, e.checked)}
            />
          )}
        />
      )}
    </DataTable>
  );
}

/**
 * Dialog to upload a medical file
 * @param {object} props
 * @param {boolean} props.show
 * @param {() => void} props.onHide
 * @param {(state: { fileType: string, file: File & { objectURL: string } }) => void} props.onSave
 * @param {() => void} props.onUpload
 * @param {number} props.caseId
 */
function MedicalFileDialog({ show, onHide, onSave, onUpload, caseId }) {
  const [formState, setFormState] = useState({
    fileType: null,
    file: null,
    fileName: "",
  });

  return (
    <Dialog
      header="Cargar soporte médico"
      visible={show}
      onHide={onHide}
      draggable={false}
      style={{ minWidth: "35rem" }}
      contentClassName="p-fluid"
    >
      <div className="p-field">
        <label htmlFor="medicalFileType">Tipo de soporte</label>
        <Dropdown
          inputId="medicalFileType"
          className="mt-2"
          options={MEDICAL_FILE_TYPES}
          placeholder="Seleccionar tipo"
          value={formState.fileType}
          onChange={(e) => setFormState({ ...formState, fileType: e.value })}
        />
      </div>
      <div className="p-field p-fluid">
        <label htmlFor="medicalFile">Soporte médico</label>
        <FileUpload
          className="mt-2"
          name="file"
          mode="advanced"
          chooseLabel="Elegir archivo"
          uploadOptions={{
            style: { display: "none" },
          }}
          cancelLabel="Cancelar"
          emptyTemplate={<p className="m-0">Cargar Informe o Estudios</p>}
          onSelect={(e) => {
            if (e.files.length) {
              setFormState({ ...formState, file: e.files[0].objectURL });
              // console.log(e.files[0].type);
            } else {
              setFormState({ ...formState, file: null });
            }
          }}
          url={`${BASE_URL}insurance/medical-case-file/`}
          auto
          accept=".jpeg,.jpg,.png,.pdf"
          onBeforeUpload={({ formData, xhr }) => {
            formData.append("medical_case", caseId);
            formData.append("type_requirement", formState.fileType);

            const reqListener = (r) => {
              console.log("rrr", r);
              console.log(r.target.response);
              // convert the JSON string to a JSON object
              const data = JSON.parse(r.target.response);
              console.log(data);
              setFormState({
                ...formState,
                file: data.file,
                fileName: data.file_name,
                fileType: data.type_requirement,
              });
            };

            xhr.addEventListener("load", reqListener);
          }}
          onUpload={onUpload}
          onBeforeSend={(e) => {
            console.log("onBeforeSend", e);
          }}
          maxFileSize={5000000} // 5MB
          invalidFileSizeMessageDetail="El tamaño máximo permitido es 5MB"
        />
      </div>
      <Button
        severity="success"
        label="Confirmar"
        icon="pi pi-save"
        disabled={formState.file === null || formState.fileType === null}
        onClick={() => {
          onSave(formState);
          setFormState({ file: null, fileType: null, fileName: "" });
          onHide();
        }}
      />
    </Dialog>
  );
}
