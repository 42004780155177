import { useMutation } from "@tanstack/react-query";
import { updateCaseStatus } from "../services/medicalCases";
import { medicalCaseToPending } from "../services/medicalCases";

/**
 * Hook to update the status of a medical case
 * @param {object} params
 * @param {({ type, message }: { type: string, message: string }) => void} params.onSuccess
 */
export function usePatchMedicalCase({ onSuccess }) {
  return useMutation({
    mutationFn: updateCaseStatus,
    onSuccess: onSuccess,
  });
}

export function useSuscribeCaseToPendingMutation ({onSuccess}){
  return useMutation({
    mutationFn: medicalCaseToPending,
    onSuccess: onSuccess,
  });
}
