import { BASE_URL } from "../constants";
import { getAuthHeader } from "../utils";

/**
 * @typedef Insurance
 * @property {number} id
 * @property {string} logo
 * @property {string} name
 */


export async function getInsurance() {
  try {
    const response = await fetch(`${BASE_URL}insurance/get-insurance/`, {
      headers: {
        ...getAuthHeader(),
      },
    });
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    /**@type {Insurance} */
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching medical center", error);
    return null;
  }
}